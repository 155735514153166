import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as PayPalLogo } from '@/assets/paypallogo.svg';
import { ReactComponent as StripeLogo } from '@/assets/stripe.svg';
import { Button, SubscriptionCard } from '@/components';
import { DiscountInformation } from '@/redux/dto/user';
import { Dispatch, RootState } from '@/redux/store';
import * as routes from '@/routes/routes';
import { AVAILABLE_SUBSCRIPTIONS, subscriptionCardsInfo } from '@/utils/constants';

import { SubscriptionType } from '@/hooks/IsPickedPlan';

type FormInputs = {
  paymentMethod: string;
};

type Props = {
  isTrial: boolean;
};

const StripeAndPaypal = ({ isTrial }: Props) => {
  const navigate = useNavigate();

  const stripe = useStripe();
  const dispatch = useDispatch<Dispatch>();
  const stateUserEmail = useSelector((state: RootState) => state.auth.user?.account?.email);
  const elements = useElements();
  const [isAgree, setIsAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState<string | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('e') || stateUserEmail;
  const coupon = urlParams.get('coupon');

  const plan = urlParams.get('package');
  const isAvaliableSwtichPlan = plan === null;

  const { register, handleSubmit, watch } = useForm<FormInputs>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      paymentMethod: 'stripe',
    },
  });

  const isPaymentMethodStripe = watch('paymentMethod') === 'stripe';

  useEffect(() => {
    setIsCardCvcValid(false);
    setIsCardExpiryValid(false);
    setIsCardNumberValid(false);
  }, [isPaymentMethodStripe]);

  const [selectedPlan, setSelectedPlan] = useState<SubscriptionType | any>(
    AVAILABLE_SUBSCRIPTIONS.pro,
  );

  const [discountInfo, setDiscountInfo] = useState<DiscountInformation>();

  const [isCardNumberValid, setIsCardNumberValid] = useState<boolean>(false);
  const [isCardExpiryValid, setIsCardExpiryValid] = useState<boolean>(false);
  const [isCardCvcValid, setIsCardCvcValid] = useState<boolean>(false);

  const handleCardNumberChange = (event: any) => {
    setIsCardNumberValid(event.complete && !event.error);
  };

  const handleCardExpiryChange = (event: any) => {
    setIsCardExpiryValid(event.complete && !event.error);
  };

  const handleCardCvcChange = (event: any) => {
    setIsCardCvcValid(event.complete && !event.error);
  };

  useEffect(() => {
    coupon && checkDiscount();
  }, [plan, coupon]);

  const savePickedPlanToStore = async () => {
    await dispatch.auth.savePickedPlan({ type: selectedPlan });
  };

  useEffect(() => {
    savePickedPlanToStore();
  }, [selectedPlan]);

  const checkDiscount = async () => {
    const discountInfo = await dispatch.auth.checkDiscountCode({ code: coupon, product: plan });
    if (discountInfo?.canBeUsed) {
      setSelectedPlan(
        plan ? plan : discountInfo.discountObject?.products[0]?.name || AVAILABLE_SUBSCRIPTIONS.pro,
      );
      setDiscountInfo(discountInfo);
    }
  };

  const createPurchaseStripe = async (event: Event, data: FormInputs) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      return;
    }
    const { error: paymentError, paymentMethod: paymentMethodResponse } =
      await stripe.createPaymentMethod({
        type: 'card',
        billing_details: {
          name: 'test',
          email: 'test@test.com',
        },
        card: cardElement,
      });

    if (paymentError) {
      setStripeErrorMessage(paymentError.message || null);
      return;
    }

    return paymentMethodResponse.id;
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data, event: Event) => {
    try {
      setIsLoading(true);
      if (isPaymentMethodStripe) {
        const paymentMethodId = await createPurchaseStripe(event, data);
        await dispatch.auth.createPaymentMethodStripe({
          paymentMethodId,
          email,
        });
        await dispatch.auth.createSubscriptionAgreement({
          product: selectedPlan,
          coupon,
        });

        navigate(routes.CALLBACK);
      } else {
        const { approveUrl } = await dispatch.auth.setupPayPallToken();
        window.location.assign(approveUrl);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const disableSubmitButton = isPaymentMethodStripe
    ? !isAgree || !isCardCvcValid || !isCardExpiryValid || !isCardNumberValid
    : !isAgree;

  return (
    <div className="flex size-full max-h-full min-h-screen max-w-[335px] flex-col">
      {selectedPlan && (
        <div className="mb-6 w-full ">
          {isAvaliableSwtichPlan ? (
            <div>
              {subscriptionCardsInfo.map(el => (
                <SubscriptionCard
                  {...el}
                  key={el.label}
                  variant={coupon ? 'default' : 'minimal'}
                  discount={discountInfo?.discountObject}
                  active={selectedPlan}
                  setActive={setSelectedPlan}
                />
              ))}
            </div>
          ) : (
            <SubscriptionCard
              variant="minimal"
              active={selectedPlan}
              discount={discountInfo?.discountObject}
              type={selectedPlan}
              label={
                subscriptionCardsInfo.find(card => card.type === selectedPlan)?.label ||
                AVAILABLE_SUBSCRIPTIONS.starter
              }
            />
          )}
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full">
          <h1 className="text-xxl font-F37Bolton-Medium">Sign up with</h1>

          <div className="mb-2 mt-[18px] flex items-center rounded-[3px] border border-gray-300 p-4">
            <input type="radio" id="paypal" value="paypal" {...register('paymentMethod')} />
            <label htmlFor="paypal" className="flex items-center">
              <PayPalLogo className="mx-[8px]" /> PayPal
            </label>
          </div>

          <div className="flex flex-col rounded-[3px] border border-gray-300 p-3">
            <div className="mb-2 flex items-center">
              <div>
                <label htmlFor="stripe" className="flex items-center">
                  <input type="radio" id="stripe" value="stripe" {...register('paymentMethod')} />
                  <StripeLogo className="mx-[8px]" />
                  Stripe
                </label>
              </div>
            </div>

            {isPaymentMethodStripe && (
              <div className="grid-container gap-4 space-y-4 border-t border-darkblue-100 py-4">
                <div className="space-y-2">
                  <label>Card number</label>
                  <CardNumberElement
                    onChange={handleCardNumberChange}
                    options={{
                      classes: {
                        empty: 'border-gray-300',
                        base: 'text-xl bg-white border rounded-md  border-gray-300 text-gray-900 items-center py-[10px] px-[12px]',
                        invalid: 'border-red border',
                      },
                    }}
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label>Expiration date</label>
                    <CardExpiryElement
                      onChange={handleCardExpiryChange}
                      options={{
                        classes: {
                          base: 'bg-white border rounded-md border-gray-300 text-gray-900 items-center py-[10px] px-[12px]',
                        },
                      }}
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Security code</label>

                    <CardCvcElement
                      onChange={handleCardCvcChange}
                      options={{
                        classes: {
                          base: 'bg-white border rounded-md border-gray-300 text-gray-900 items-center py-[10px] px-[12px]',
                        },
                      }}
                    />
                  </div>
                </div>
                {stripeErrorMessage && (
                  <span className="flex h-auto w-full flex-col items-start text-sm text-red-500">
                    {stripeErrorMessage}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="mt-[20px]">
          <label className="inline-block items-center">
            <input
              key={1}
              type="checkbox"
              name="checkbox"
              checked={isAgree}
              onChange={() => {
                setIsAgree(!isAgree);
              }}
              className="mr-[10px] rounded border border-gray-300 focus:ring-0 focus:ring-offset-0"
            />
            I have read and agree to the
            <span
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                window.open('https://www.paystubs.com/legal/terms-and-conditions', '_blank');
              }}
              className="mx-1 text-blue-500 underline hover:cursor-pointer"
            >
              terms of use
            </span>
            and
            <span
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                window.open('https://www.paystubs.com/legal/privacy-policy', '_blank');
              }}
              className="mx-1 text-blue-500 underline hover:cursor-pointer"
            >
              privacy policy
            </span>
          </label>
        </div>

        <div className="mt-[20px]">
          <Button
            children={isTrial ? 'Start free trial & claim discount' : 'Start your subscription'}
            type={isLoading ? 'loading' : 'primary'}
            onClick={() => onSubmit}
            isDisabled={disableSubmitButton}
          />
        </div>
      </form>
    </div>
  );
};

export default StripeAndPaypal;
