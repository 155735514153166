import { Controller, FormProvider } from 'react-hook-form';

import { ReactComponent as PlantIllustration } from '@/assets/illustration/plant.svg';
import Button from '@/components/Button/Button';
import DialogComponent from '@/components/Dialog/DialogComponent';
import RadioButtonGroup from '@/components/RadioButtonGroup/RadioButtonGroup';

import { CustomizationModalProps } from './types';
import useCustomizationModal from './useCustomizationModal';

const CustomizationModal = ({
  isOpen,
  onCancel,
  onSaveClickHandler,
  options,
  isSaving,
}: CustomizationModalProps) => {
  const { onSubmit, form } = useCustomizationModal({
    onSaveClickHandler,
  });

  return (
    <DialogComponent
      width="w-full max-w-[95vw] sm:max-w-[480px]"
      height="h-auto"
      modalClasses="rounded-[12px] bg-white flex flex-col justify-center sm:block"
      open={isOpen}
      onCancel={() => onCancel()}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="mx-auto w-full max-w-[460px] p-8 sm:px-16">
            <div className="flex justify-center">
              <PlantIllustration className="h-[91px] w-[65px]" />
            </div>

            <div className="mt-4">
              <span className="block text-center font-F37Bolton-Medium text-2xl">
                Welcome to PayStubs.com!
              </span>
              <div className="flex justify-center ">
                <span className="font-F37Bolton-Trial w-full max-w-[311px] text-center text-lg font-normal text-gray-400 ">
                  Before setting up, please tell us how you currently manage payroll:
                </span>
              </div>

              <div className="my-4">
                <Controller
                  name="managePayroll"
                  control={form.control}
                  render={({
                    field,
                    formState: {
                      errors: { managePayroll: managePayrollError },
                    },
                  }) => {
                    return (
                      <RadioButtonGroup
                        onChange={value => {
                          field.onChange(value);
                        }}
                        selectedValue={field.value}
                        label=""
                        optionWrapperClasses="flex-col gap-y-2"
                        options={options}
                        errorMessage={managePayrollError?.message ?? ''}
                      />
                    );
                  }}
                />
              </div>

              <div>
                <Button variant="primary" width="w-full" type="submit" isLoading={isSaving}>
                  Start setting up
                </Button>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default CustomizationModal;
