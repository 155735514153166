import { TRIAL_DAYS } from '@/utils/constants';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import Button from '../Button/Button';
import { PricingPlanProps } from './types';

const PricingPlan: FC<PricingPlanProps> = ({
  plan,
  description,
  price,
  onPress,
  trialDays = TRIAL_DAYS,
  parentClasses = '',
  isTrialButtonVisible = true,
}) => {
  return (
    <div
      className={twMerge(
        'flex w-[335px] flex-col items-center rounded-[12px] border border-gray-200 bg-white px-[16px] py-[24px]',
        parentClasses,
      )}
    >
      <div className="flex items-center justify-center">
        <div className="rounded-[8px] bg-emerald-100 px-[8px] py-[4px] text-emerald-500">
          {plan} Plan
        </div>
        <span className="ml-[8px] text-sm text-gray-300">Unlimited Payroll</span>
      </div>
      <span className="mb-[8px] mt-[16px] block w-[250px] text-center text-[18px]">
        {description}
      </span>
      <div className="flex items-center gap-x-[5px]">
        <span className="font-F37Bolton-Medium text-[48px] text-emerald-500">{price}</span>
        <span className="text-gray-300">/ month</span>
      </div>
      {isTrialButtonVisible && (
        <>
          <span className="my-[16px] block text-sm text-gray-300">
            Cancel anytime. Guaranteed{' '}
            <span className="font-F37Bolton-Medium underline">full refund</span>.
          </span>
          <div>
            <Button
              variant="primary"
              width="w-[306px]"
              children={`Start ${trialDays}-day free trial`}
              onPress={onPress}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PricingPlan;
