import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';
import { BrowserTracing, Replay, init as initSentry } from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CobrowseIO from 'cobrowse-sdk-js';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { Auth0ProviderWithNavigate } from './hooks/authHook';
import './index.css';
import { whiteListErrorsSentry } from './utils/constants';

FullStory.init({ orgId: import.meta.env.APP_FULLSTORY_ORG, namespace: 'FullStory' });

if (import.meta.env.PROD) {
  initSentry({
    dsn: import.meta.env.APP_DNS_SENTRY,
    integrations: [
      new BrowserTracing(),
      new Replay(),
      new SentryFullStory(import.meta.env.APP_SENTRY_ORG_SLUG, { client: FullStory }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    release: import.meta.env.APP_VERSION,
    environment: import.meta.env.APP_MODE,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: whiteListErrorsSentry,
  });
}

const GTM_ID = import.meta.env.APP_GTM_ID;

const tagManagerArgs = {
  gtmId: GTM_ID,
};

TagManager.initialize(tagManagerArgs);

const STRIPE_TOKEN = import.meta.env.APP_STRIPE_TOKEN;
const stripePromise = loadStripe(STRIPE_TOKEN);

const COBROWSE_LICENSE = import.meta.env.APP_COBROWSE_LICENSE;

if (COBROWSE_LICENSE) {
  CobrowseIO.license = COBROWSE_LICENSE;
  CobrowseIO.start();
}

const options = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  //For Devs
  // <React.StrictMode>
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <Elements stripe={stripePromise} options={options}>
        <App />
      </Elements>
    </Auth0ProviderWithNavigate>
  </BrowserRouter>,
  // </React.StrictMode>,
);
