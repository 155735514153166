import { lazy } from 'react';

import * as routes from '@/routes/routes';

type RouteItemDataType = {
  path: string;
  loadPageComponent?: any;
  navigateTo?: string;
  caseSensitive?: boolean;
  replace?: boolean;
  state?: Record<string, any>;
};

interface Routes {
  privateRoutes: RouteItemDataType[];
  publicRoutes: RouteItemDataType[];
  accountSetupRoutes: RouteItemDataType[];
}

const pagesRoutesData: Routes = {
  privateRoutes: [
    {
      path: routes.DASHBOARD,
      loadPageComponent: lazy(() => import('@/pages/dashboard')),
    },
    {
      path: routes.TEAM_MEMBERS,
      loadPageComponent: lazy(() => import('@/pages/teamMembers/TeamMembersPage')),
    },
    {
      path: routes.CURRENT_EMPLOYEE,
      loadPageComponent: lazy(() => import('@/pages/teamMembers/EditTeamMember/EditEmployee')),
    },
    {
      path: routes.CURRENT_CONTRACTOR,
      loadPageComponent: lazy(() => import('@/pages/teamMembers/EditTeamMember/EditContractor')),
    },
    {
      path: routes.ADD_EMPLOYEE,
      loadPageComponent: lazy(() => import('@/pages/teamMembers/AddNewMemberStepper')),
    },
    {
      path: routes.ADD_CONTRACTOR,
      loadPageComponent: lazy(() => import('@/pages/teamMembers/AddNewMemberStepper')),
    },
    {
      path: routes.COMPANY,
      loadPageComponent: lazy(() => import('@/pages/businessProfile/BusinessProfile')),
    },
    {
      path: routes.PAY_CONTRACTORS,
      loadPageComponent: lazy(() => import('@/pages/contractorPayment/ContractorPayment')),
    },
    {
      path: routes.RECORD_CONTRACTOR_PAYMENT_ADD_INFORMATION,
      loadPageComponent: lazy(
        () => import('@/pages/contractorPayment/PaymentInsertInformationPage'),
      ),
    },
    {
      path: routes.RECORD_CONTRACTOR_PAYMENT_REVIEW,
      loadPageComponent: lazy(() => import('@/pages/contractorPayment/PaymentReview')),
    },
    {
      path: routes.RECORD_CONTRACTOR_APPROVED_PAYMENT,
      loadPageComponent: lazy(() => import('@/pages/contractorPayment/ApprovedPayment')),
    },
    {
      path: routes.RECORD_PAYMENT_ADD_INFORMATION,
      loadPageComponent: lazy(
        () => import('@/pages/contractorPayment/PaymentInsertInformationPage'),
      ),
    },
    {
      path: routes.RECORD_PAYMENT_DETAILS,
      loadPageComponent: lazy(() => import('@/pages/contractorPayment/PaymentDetails')),
    },

    {
      path: routes.PAYROLL_HISTORY,
      loadPageComponent: lazy(() => import('@/pages/payrollHistory')),
    },

    {
      path: routes.PAYROLL_HISTORY_CURRENT,
      loadPageComponent: lazy(() => import('@/pages/payrollHistory/DetailsPayrollPage')),
    },
    {
      path: routes.PAYROLL_HISTORY_CURRENT_EMPLOYEE,
      loadPageComponent: lazy(() => import('@/pages/payrollHistory/DetailsPayrollPageEmployee')),
    },
    {
      path: routes.PAYROLL_HISTORY_CURRENT_CONTRACTOR,
      loadPageComponent: lazy(() => import('@/pages/payrollHistory/DetailsPayrollPageContractor')),
    },

    {
      path: routes.DASHBOARD_UPDATE_EING_SSN,
      loadPageComponent: lazy(() => import('@/pages/dashboard/AddEinOrSsnPage')),
    },
    {
      path: routes.MY_ACCOUNT,
      loadPageComponent: lazy(() => import('@/pages/myAccount')),
    },
    {
      path: routes.SUPPORT,
      loadPageComponent: lazy(() => import('@/pages/customerSupport')),
    },
    {
      path: routes.PRIVACY_POLICY,
      loadPageComponent: lazy(() => import('@/pages/privacyPolicy')),
    },
    {
      path: routes.ERROR_PAGE,
      loadPageComponent: lazy(() => import('@/pages/error/ErrorPage')),
    },
    {
      path: routes.RUN_PAYROLL,
      loadPageComponent: lazy(() => import('@/pages/runPayroll')),
    },
    {
      path: routes.RUN_PAYROLL_PREVIEW,
      loadPageComponent: lazy(() => import('@/pages/runPayroll/PreviewPayStub')),
    },
    {
      path: routes.RUN_PAYROLL_APPROVE,
      loadPageComponent: lazy(() => import('@/pages/runPayroll/ApprovedPayStub')),
    },
    {
      path: routes.RUN_PAYROLL_ADD_INFORMATION,
      loadPageComponent: lazy(() => import('@/pages/runPayroll/PayrollInsertInformationPage')),
    },
    {
      path: routes.PAYROLL_HISTORY_SETUP,
      loadPageComponent: lazy(() => import('@/pages/runPayroll/PayrollHistorySetup')),
    },
    {
      path: routes.EMPLOYEE_PAYROLL_HISTORY_SETUP,
      loadPageComponent: lazy(() => import('@/pages/runPayroll/SetUpEmployeePayHistoryPage')),
    },
    {
      path: routes.RUN_PAYROLL_DETAILS,
      loadPageComponent: lazy(() => import('@/pages/runPayroll/RunPayrollDetails')),
    },
    {
      path: routes.RUN_PAYROLL_DETAILS_PAYSTUB,
      loadPageComponent: lazy(() => import('@/pages/runPayroll/DetailsPaystub')),
    },
    {
      path: routes.PAYROLL_SETTINGS,
      loadPageComponent: lazy(() => import('@/pages/payrollSettings/PayrollSettingsPage')),
    },
    {
      path: routes.TAX_FORMS,
      loadPageComponent: lazy(() => import('@/pages/taxForms')),
    },
    {
      path: routes.TAX_LIABILITY,
      loadPageComponent: lazy(() => import('@/pages/taxLiability/TaxLiabilitySchedulePage')),
    },
    {
      path: routes.TAX_LIABILITY_REPORT,
      loadPageComponent: lazy(() => import('@/pages/taxLiability/TaxLiabilityReportPage')),
    },
    {
      path: routes.TAX_FORM_CONTRACTOR_1099S,
      loadPageComponent: lazy(() => import('@/pages/taxForms/partials/Contractor/Details')),
    },
    {
      path: routes.TAX_FORM_EMPLOYEE_W2,
      loadPageComponent: lazy(() => import('@/pages/taxForms/partials/Employee/Details')),
    },
    // {
    //   path: routes.PAYROLL_SCHEDULE,
    //   loadPageComponent: lazy(() => import('@/pages/payrollSchedule')),
    // },
    {
      path: routes.PAYROLL_SCHEDULE_CREATE,
      loadPageComponent: lazy(() => import('@/pages/payrollSchedule/CreateNewSchedule')),
    },
    {
      path: routes.PAYROLL_SCHEDULE_EDIT,
      loadPageComponent: lazy(() => import('@/pages/payrollSchedule/EditSchedule')),
    },
    {
      path: routes.MY_ACCOUNT_BILLING_CENTER,
      navigateTo: routes.MY_ACCOUNT,
      state: {
        tabIndex: 1,
      },
    },
    {
      path: '*',
      loadPageComponent: lazy(() => import('@/pages/error/ErrorPage')),
    },
  ],
  accountSetupRoutes: [
    {
      path: routes.ACCOUNT_SETUP_COMPANY,
      loadPageComponent: lazy(() => import('@/pages/accountSetup/Company')),
    },
    {
      path: routes.ACCOUNT_SETUP_PAYROLL_SETTINGS,
      loadPageComponent: lazy(() => import('@/pages/accountSetup/PayrollSettings')),
    },
    {
      path: routes.ACCOUNT_SETUP_TAXES,
      loadPageComponent: lazy(() => import('@/pages/accountSetup/AccountSetupTaxes')),
    },
    {
      path: routes.ACCOUNT_SETUP_CONTRACTORS,
      loadPageComponent: lazy(() => import('@/pages/accountSetup/Contractors')),
    },
    {
      path: routes.ACCOUNT_SETUP_EMPLOYEE,
      loadPageComponent: lazy(() => import('@/pages/accountSetup/AccountSetupEmployee')),
    },
    {
      path: routes.ACCOUNT_SETUP_CURRENT_EMPLOYEE,
      loadPageComponent: lazy(() => import('@/pages/accountSetup/EditTeamMember/EditEmployee')),
    },
    {
      path: routes.ACCOUNT_SETUP_CURRENT_CONTRACTOR,
      loadPageComponent: lazy(() => import('@/pages/accountSetup/EditTeamMember/EditContractor')),
    },
    {
      path: routes.ACCOUNT_SETUP_FINISH_ONBOARDING,
      loadPageComponent: lazy(() => import('@/pages/accountSetup/FinishOnboarding')),
    },
  ],
  publicRoutes: [
    {
      path: '/',
      navigateTo: '/login',
      replace: true,
    },
    {
      path: routes.TEST_MOBILE_PAY,
      loadPageComponent: lazy(() => import('@/components/shared/ButtonMobilePay')),
    },
    {
      path: routes.SIGNUP_STEPPER,
      loadPageComponent: lazy(() => import('@/pages/auth/SignUpStepper')),
    },
    {
      path: routes.CALLBACK,
      loadPageComponent: lazy(() => import('@/pages/auth/CallBackPage')),
    },
    {
      path: routes.SUCCESS_PURCHASE_CONTINUE_ONBOARDING,
      loadPageComponent: lazy(() => import('@/pages/auth/CallBackPage')),
    },
    {
      path: routes.SIGNIN,
      loadPageComponent: lazy(() => import('@/pages/auth/SignInPage')),
    },
    {
      path: routes.SIGNUP,
      loadPageComponent: lazy(() => import('@/pages/auth/SignUpPage')),
      caseSensitive: true,
    },
    {
      path: routes.SIGNUP_SIMPLE,
      loadPageComponent: lazy(() => import('@/pages/alternativeSignup')),
    },
    {
      path: routes.SUCCESS_PURCHASE,
      loadPageComponent: lazy(() => import('@/pages/successPurchase')),
    },
    {
      path: routes.SUCCESS_PAYPALL,
      loadPageComponent: lazy(() => import('@/pages/paypall')),
    },
    {
      path: routes.CANCELL_PURCHASE,
      loadPageComponent: lazy(() => import('@/pages/cancelPurchase')),
    },
    {
      path: routes.RECEIPT_DOWNLOAD,
      loadPageComponent: lazy(() => import('@/pages/pdfReceipt/DownloadReceiptPage')),
    },
    {
      path: routes.RECEIPT_PRINT_CONTRACTORS,
      loadPageComponent: lazy(() => import('@/pages/pdfReceipt/PrintReceiptPage')),
    },
    {
      path: routes.RECEIPT_PRINT_CONTRACTOR,
      loadPageComponent: lazy(() => import('@/pages/pdfReceipt/PrintReceiptContractorPage')),
    },
    {
      path: routes.EMPLOYEE_INVITATION,
      loadPageComponent: lazy(() => import('@/pages/employeeInvitation')),
    },
    {
      path: routes.LOGOUT,
      loadPageComponent: lazy(() => import('@/pages/auth/Logout')),
    },
    {
      path: '*',
      loadPageComponent: lazy(() => import('@/pages/error/ErrorPage')),
    },
  ],
};

export default pagesRoutesData;
