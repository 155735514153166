import { useState } from 'react';

import { SubscriptionType } from '@/hooks/IsPickedPlan';
import { Discount } from '@/redux/dto/user';
import { AVAILABLE_SUBSCRIPTIONS, subscriptionCardsInfo } from '@/utils/constants';

import { SubscriptionCard } from '..';

export type SubscriptionCardGroupProps = {
  description: string;
  buttonText: string;
  variant?: 'default' | 'minimal';
  discount?: Discount;
  onPurchase: (paymentMethodId: string, type: SubscriptionType) => void;
  onPurchasePayPall: (type: SubscriptionType) => void;
  isLoading: boolean;
  isSubscriptionPlan?: boolean;
  activePlan?: SubscriptionType;
  planDescription: boolean;
  setSelectedPlan: (e: SubscriptionType) => void;
  hideCheckoutContainer?: boolean;
};

const SubscriptionCardGroup = ({
  description,
  buttonText,
  onPurchasePayPall,
  onPurchase,
  isLoading,
  isSubscriptionPlan,
  activePlan,
  planDescription,
  variant,
  discount,
  setSelectedPlan,
  hideCheckoutContainer = false,
}: SubscriptionCardGroupProps) => {
  const [active, setActive] = useState<SubscriptionType>(activePlan || AVAILABLE_SUBSCRIPTIONS.pro);

  return (
    <>
      <div
        className={
          isSubscriptionPlan
            ? 'flex w-full flex-col gap-4'
            : 'flex flex-col gap-4 xl:grid xl:max-w-full xl:grid-cols-2'
        }
      >
        {subscriptionCardsInfo.map(el => (
          <SubscriptionCard
            {...el}
            isSubscriptionPlan={isSubscriptionPlan}
            key={el.label}
            variant={variant}
            discount={discount}
            active={active}
            setActive={e => {
              setActive(e);
              setSelectedPlan(e);
            }}
          />
        ))}
      </div>
      <span className="mt-[20px] block w-full max-w-[335px] text-center text-sm text-[#4D4D4D] xl:max-w-full">
        {description}
      </span>
      {/* <div className="mt-[20px] pb-[10px]">
        <ChoosePaymentMethod
          isLoading={isLoading}
          onClickPayPal={() => onPurchasePayPall(active)}
          onClickStripe={(paymentMethodId: string) => onPurchase(paymentMethodId, active)}
        />
      </div> */}
    </>
  );
};

export default SubscriptionCardGroup;
