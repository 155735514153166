import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Button, LogoutModal } from '@/components';
import { navigation } from '@/utils/constants';
import { keyUp } from '@/utils/helpers';

const SideBar = () => {
  const { logout } = useAuth0();
  const location = useLocation();
  const currentPath = location.pathname;

  const [isOpenedLogoutModal, setisOpenedLogoutModal] = useState<boolean>(false);
  const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

  const handleLogout = async () => {
    try {
      localStorage.clear();
      logout();
      window.fcWidget.user.clear().then(
        function () {
          console.log('FcWidget User cleared');
        },
        function () {
          console.error('FcWidget User Not cleared');
        },
      );
      window.FreshworksWidget('destroy');
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className="fixed hidden max-h-[100vh] w-[270px] flex-col overflow-y-auto border-r border-gray-200 bg-white pb-4 pt-5 lg:flex lg:h-[100vh] menu-height:pt-0">
      <div className="mt-5 flex grow flex-col justify-between pb-[40px] menu-height:mt-0">
        <nav className="relative flex flex-1 flex-col space-y-1 bg-white " aria-label="Sidebar">
          {navigation.map((item, index) => {
            const isCurrent = currentPath.startsWith(item.path);
            if (item.label) {
              return (
                <span
                  key={index}
                  className="ml-[40px] mr-[12px] flex pb-[15px] pt-[20px] text-sm text-gray-400 menu-height:py-0"
                  aria-hidden="true"
                >
                  {item.name}
                </span>
              );
            }
            return (
              <Link
                key={item.path}
                to={item.path}
                className={`
                  ${
                    isCurrent
                      ? 'border-l-[3px] border-blue-500 text-blue-500'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }
                  group flex h-[44px] items-center
                `}
              >
                <item.icon
                  className={`
                    ${
                      isCurrent
                        ? 'ml-[37px] text-blue-500'
                        : 'text-gray-500 group-hover:text-gray-500'
                    }
                    ml-[40px] mr-[12px] size-6
                  `}
                  aria-hidden="true"
                />
                <span className="flex-1">{item.name}</span>
              </Link>
            );
          })}
        </nav>
        <div className="flex flex-col items-start justify-center pb-[30px]">
          <div
            className="ml-[40px] mt-[40px] flex h-[44px] items-center menu-height:static"
            onKeyUp={keyUp}
            tabIndex={0}
            role="button"
          >
            <Button
              type={'logout'}
              isDisabled={isAppModeDemo}
              onClick={() => setisOpenedLogoutModal(!isOpenedLogoutModal)}
            />
          </div>
          <span className="ml-[43px] flex h-[44px] items-center menu-height:static">
            <span
              onKeyUp={keyUp}
              tabIndex={0}
              role="button"
              className="mr-[5px] text-blue-500 hover:cursor-pointer hover:text-blue-700"
              onClick={() =>
                window.open('https://www.paystubs.com/legal/terms-and-conditions', '_blank')
              }
            >
              T&C
            </span>{' '}
            and{' '}
            <span
              onKeyUp={keyUp}
              tabIndex={0}
              role="button"
              className="ml-[5px] text-blue-500 hover:cursor-pointer hover:text-blue-700"
              onClick={() => window.open('https://www.paystubs.com/legal/privacy-policy', '_blank')}
            >
              Privacy Policy
            </span>
          </span>
          {/* <div className="menu-height:mt-[20px]  mb-2 ml-[40px] flex h-[44px] items-center menu-height:static">
            <Button
              type="link"
              children="Terms and conditions"
              onClick={() =>
                window.open('https://www.paystubs.com/legal/terms-and-conditions', '_blank')
              }
            />
          </div>
          <div className="menu-height:mt-[20px]  mb-2 ml-[40px] flex h-[44px] items-center menu-height:static">
            <Button
              type="link"
              children="Privacy Policy"
              onClick={() => window.open('https://www.paystubs.com/legal/privacy-policy', '_blank')}
            />
          </div> */}
        </div>
      </div>
      {isOpenedLogoutModal && (
        <LogoutModal
          isOpen={isOpenedLogoutModal}
          toggleModal={isOpen => setisOpenedLogoutModal(isOpen)}
          onAction={handleLogout}
        />
      )}
    </div>
  );
};

export default SideBar;
