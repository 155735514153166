import { Dialog } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CardAdvancedPlus } from '@/assets/advanced-plus.svg';
import { ReactComponent as CardAdvancedIcon } from '@/assets/subCheckmarkAdvanced.svg';
import { ReactComponent as CardBasicIcon } from '@/assets/subCheckmarkBasic.svg';
import { ReactComponent as CardStarterIcon } from '@/assets/subCheckmarkStarter.svg';
import { Button } from '@/components';
import { Dispatch, RootState } from '@/redux/store';
import * as routes from '@/routes/routes';
import {
  AVAILABLE_SUBSCRIPTIONS,
  allPlansIncludes,
  subscriptionCardsInfo,
} from '@/utils/constants';

import { SubscriptionCard } from '../SubscriptionCard/SubscriptionCard';

export interface UpgradePlanModalProps {
  isOpen: boolean;
  currentPlan: string;
  maxSeats: number;
  showAllSubscriptions?: boolean;
  toggleModal: (isOpen: boolean) => void;
}

export const renderIconSubscription = (plan: string) => {
  switch (plan) {
    case AVAILABLE_SUBSCRIPTIONS.starter:
      return <CardStarterIcon className="size-12" />;
    case AVAILABLE_SUBSCRIPTIONS.pro:
      return <CardBasicIcon className="size-12" />;
    case AVAILABLE_SUBSCRIPTIONS.advanced:
      return <CardAdvancedIcon className="size-12" />;
    case AVAILABLE_SUBSCRIPTIONS.advancedPlus:
      return <CardAdvancedPlus className="size-12" />;
    default:
      return <CardAdvancedIcon className="size-12" />;
  }
};

const UpgradePlanModal: React.FC<UpgradePlanModalProps> = ({
  isOpen,
  currentPlan,
  maxSeats,
  showAllSubscriptions = false,
  toggleModal,
}: UpgradePlanModalProps) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const [active, setActive] = useState<string | undefined>(
    showAllSubscriptions ? currentPlan : undefined,
  );

  const { availableProducts } =
    useSelector((state: RootState) => state?.auth?.subscriptionPlan) ?? [];
  const [currentStep, setStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchSubscriptionPlan = async () => {
    try {
      await dispatch.auth.getSubscriptionPlan();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  useEffect(() => {
    fetchSubscriptionPlan();
  }, []);

  const filteredSubscriptionCards = availableProducts
    ?.sort((a, b) => a.availablePrices[0].amount - b.availablePrices[0].amount)
    .filter(card => {
      if (currentPlan === AVAILABLE_SUBSCRIPTIONS.starter) {
        return card.name !== AVAILABLE_SUBSCRIPTIONS.starter;
      }
      if (currentPlan === AVAILABLE_SUBSCRIPTIONS.pro) {
        return [AVAILABLE_SUBSCRIPTIONS.advanced, AVAILABLE_SUBSCRIPTIONS.advancedPlus].includes(
          card.name,
        );
      }
      if (currentPlan === AVAILABLE_SUBSCRIPTIONS.advanced) {
        return card.name === AVAILABLE_SUBSCRIPTIONS.advancedPlus;
      }
      return false;
    });

  const trialDays = useSelector(
    (state: RootState) => state?.auth?.subscriptionInfo?.activeSubscriptionAgreement?.trialDays,
  );

  const selectedPlan = availableProducts?.find(card => card.name === active);

  const subscriptionInformation = useSelector((state: RootState) => state.auth.subscriptionInfo);

  const id = subscriptionInformation?.activeSubscriptionAgreement?.id;

  const handleUpgradePlan = async () => {
    try {
      setIsLoading(true);
      const upgradeProductStatus = await dispatch.auth.upgradeCheckoutProduct({
        product: selectedPlan.name,
        subscriptionAgreementId: id,
      });
      if (upgradeProductStatus) {
        await dispatch.company.getInformationForRunPayroll({});
        await dispatch.auth.getSubscriptionStatus();
        setStep(3);
      } else {
        setStep(1);
      }
    } catch (error) {
      await dispatch.auth.getSubscriptionStatus();
      setStep(1);
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="flex w-full flex-col justify-center">
            <div className="mx-auto mb-4">{renderIconSubscription(currentPlan)}</div>
            <div className="flex flex-col text-center">
              <h1 className="mb-2 font-F37Bolton-Medium text-2xl text-gray-900">
                Do you need a bigger plan?
              </h1>
              {currentPlan !== AVAILABLE_SUBSCRIPTIONS.advancedPlus && (
                <span className="mb-2 text-base text-gray-900">
                  In order to create pay stubs for more than {maxSeats} team members per month, you
                  can upgrade to one of the following plans:
                </span>
              )}
              {currentPlan !== AVAILABLE_SUBSCRIPTIONS.advancedPlus && trialDays > 0 && (
                <span className="my-2">Don’t worry, your trial period will not change</span>
              )}
            </div>
            <div className="mb-4">
              <div>
                {showAllSubscriptions ? (
                  subscriptionCardsInfo.map(el => (
                    <SubscriptionCard {...el} key={el.type} active={active} setActive={setActive} />
                  ))
                ) : currentPlan === AVAILABLE_SUBSCRIPTIONS.advancedPlus ? (
                  <div className="text-center">
                    If you need to create pay stubs for more than {maxSeats} team members per month,
                    you can contact our support team and request a custom plan for your business
                  </div>
                ) : (
                  filteredSubscriptionCards?.map(el => (
                    <SubscriptionCard
                      {...el}
                      key={el.name}
                      label={el.name}
                      type={el.name}
                      active={active}
                      setActive={setActive}
                      price={el.availablePrices[0].amount}
                    />
                  ))
                )}
              </div>
              <div className="mt-[20px]">
                {currentPlan !== AVAILABLE_SUBSCRIPTIONS.advancedPlus && (
                  <Button
                    type="primary"
                    children="Upgrade now"
                    isDisabled={!active}
                    onClick={() => setStep(2)}
                    width="w-full"
                  />
                )}
                {currentPlan === AVAILABLE_SUBSCRIPTIONS.advancedPlus && (
                  <Button
                    type="primary"
                    children="Request custom plan"
                    width="w-full"
                    onClick={() => window.fcWidget.open()}
                  />
                )}
              </div>
            </div>
            <div className="text-center">
              <Button
                type={'link'}
                children="Maybe later"
                onClick={() => {
                  toggleModal(!isOpen);
                  setActive(undefined);
                  setStep(1);
                }}
              />
            </div>
            <div className="mt-5">
              <h1 className="font-F37Bolton-Medium text-lg text-gray-900">All plans included: </h1>
              <ul className="list-none">
                {allPlansIncludes.map(item => (
                  <li
                    key={item}
                    className="flex min-h-[40px] w-full items-center justify-between border-b border-darkblue-100 py-[12px] text-gray-500 last:border-none"
                  >
                    <span className="max-w-[250px] text-left">{item}</span>
                    <CheckIcon className="size-4" />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="relative z-10 mx-auto max-w-lg rounded-lg bg-white">
            <div className="flex min-h-[340px] flex-col justify-center overflow-y-auto lg:min-w-[400px]">
              <div className="fle flex-col text-center ">
                <h1 className="mb-2 font-F37Bolton-Medium text-2xl text-gray-900">
                  Upgrade to {selectedPlan.name}?
                </h1>
                {trialDays > 0 && (
                  <span className="text-base text-gray-500">
                    You will be billed after your trial period ends
                  </span>
                )}
              </div>
              <div className="mb-3">
                <SubscriptionCard
                  label={selectedPlan.name}
                  type={selectedPlan.name}
                  description={selectedPlan.description}
                  price={selectedPlan.availablePrices[0].amount}
                />
              </div>
              <div className="w-full space-y-4">
                <Button
                  className="w-full"
                  type="primary"
                  children="Yes, upgrade"
                  onClick={handleUpgradePlan}
                  isLoading={isLoading}
                />
                <Button className="w-full" type="link" children="Back" onClick={() => setStep(1)} />
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="flex flex-col items-center justify-center lg:min-w-[400px]">
            <div className="flex w-[300px] flex-col text-center ">
              <div className="flex justify-center p-4">
                {renderIconSubscription(selectedPlan?.name)}
              </div>
              <h1 className="mb-2 font-F37Bolton-Medium text-2xl text-gray-900">
                Upgraded to {selectedPlan?.name}!
              </h1>
              <span className="mb-2 text-base text-gray-500">
                Congratulations! You can now create pay stubs for up to {maxSeats} team members a
                month
              </span>
              <div className="w-full">
                <span className=" text-black">
                  You can view and manage your subscription in the{' '}
                </span>
                <span
                  onClick={() => navigate(routes.MY_ACCOUNT_BILLING_CENTER)}
                  className="cursor-pointer text-blue-600 underline "
                >
                  billing center
                </span>
              </div>
            </div>
            <div className="my-4 flex flex-col justify-center space-y-2">
              <Button
                type="primary"
                children="Done"
                onClick={() => {
                  setStep(1);
                  toggleModal(!isOpen);
                  setActive(undefined);
                }}
                width="w-[150px]"
              />
            </div>
          </div>
        );
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => null}
      className="fixed inset-0 z-50 flex items-center justify-center"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

      <div className="relative z-10 mx-2 my-20 max-h-[95vh] min-h-[400px] w-full max-w-md  overflow-y-auto rounded-lg bg-white px-4 py-8 sm:mx-auto">
        {renderContent()}
      </div>
    </Dialog>
  );
};

export default UpgradePlanModal;
