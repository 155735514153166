import debounce from 'lodash.debounce';
import { forwardRef, useCallback, useMemo } from 'react';
import { TextArea as AriaTextArea, Label, Text, TextField } from 'react-aria-components';

import { extractEventName } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

import { InputTextAreaProps } from './types';

const InputTextArea = forwardRef(
  (
    {
      title,
      subtitle,
      isRequired = false,
      isOptional = false,
      width,
      errorMessage,
      onChange,
      parentClasses,
      inputClasses,
      isShowDollarIcon = false,
      inputProps,
      disabled = false,
      customOnChange,
      endAdornment,
      ...rest
    }: InputTextAreaProps,
    ref,
  ) => {
    const handleInputChange = useCallback(
      debounce(() => {
        trackEvent('inputChange', {
          name: rest.name,
          currentPage: extractEventName(location.pathname),
        });
      }, 700),
      [],
    );

    const valueChangeHandler = (value: string) => {
      handleInputChange();
      onChange && onChange(value);
      customOnChange && customOnChange(value);
    };

    const labelClassName = `font-F37Bolton-Medium text-[16px] text-gray-900`;

    const inputClassName = useMemo(() => {
      let baseClass = `h-[44px] w-full rounded-[8px] placeholder-gray-300 focus:outline-none ${
        errorMessage
          ? 'border-red-500'
          : disabled
          ? 'border-gray-100 text-gray-100'
          : 'border-gray-300 focus:ring-[1px] focus:ring-blue-500'
      }`;

      if (title) {
        baseClass = `${baseClass} mt-[8px]`;
      }

      if (isShowDollarIcon) {
        baseClass = `${baseClass} pl-[25px]`;
      }

      if (inputClasses) {
        baseClass = `${baseClass} ${inputClasses}`;
      }

      return baseClass;
    }, [errorMessage, isShowDollarIcon, width, disabled, inputClasses]);

    return (
      <TextField
        className={`relative flex flex-col ${width ? width : 'w-full'} ${parentClasses ?? ''}`}
        {...rest}
        onChange={value => {
          valueChangeHandler(value);
        }}
      >
        <Label className={labelClassName}>
          {title} {isRequired && <span className="text-red-500">*</span>}{' '}
          {isOptional && <span className="text-gray-300">(optional)</span>}
        </Label>
        <div className="relative">
          <AriaTextArea className={inputClassName} ref={ref} disabled={disabled} {...inputProps} />
          {endAdornment && endAdornment}
        </div>

        {isShowDollarIcon && (
          <span className={`absolute left-[10px] top-[42px] ${disabled ? 'text-gray-100' : ''}`}>
            $
          </span>
        )}
        {(errorMessage || subtitle) && (
          <Text
            slot="description"
            className={`mt-[8px] text-[14px] ${errorMessage ? 'text-red-500' : 'text-gray-400'}`}
          >
            {errorMessage ? errorMessage : subtitle}
          </Text>
        )}
      </TextField>
    );
  },
);
InputTextArea.displayName = 'InputTextArea';
export default InputTextArea;
