import React from 'react';

import BillingHistoryTable from './components/BillingHistoryTable/BillingHistoryTable';
import BillingHistoryView from './components/BillingHistoryView/BillingHistoryView';

interface Props {
  invoices: any;
}

const BillingHistory: React.FC<Props> = ({ invoices }) => {
  return (
    <div className="mt-10 flex flex-col gap-4">
      <span className="font-F37Bolton-Medium text-xl text-gray-900">Billing History</span>
      {invoices?.length > 0 ? (
        <>
          {invoices.map((bill: any) => (
            <BillingHistoryView data={bill} />
          ))}
          <BillingHistoryTable data={invoices} />
        </>
      ) : (
        <div className="mt-4 flex flex-col justify-center rounded-xl bg-surface-grey p-8 text-center">
          <span className="font-F37Bolton-Medium">You do not have any past invoices</span>
          <span className="pt-2 text-sm text-gray-400">
            An invoice will appear here after the first billing with PayStubs
          </span>
        </div>
      )}
    </div>
  );
};

export default BillingHistory;
