/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { ReactComponent as CardAdvancedPlus } from '@/assets/advanced-plus.svg';
import { ReactComponent as CardAdvancedIcon } from '@/assets/subCheckmarkAdvanced.svg';
import { ReactComponent as CardBasicIcon } from '@/assets/subCheckmarkBasic.svg';
import { ReactComponent as CardStarterIcon } from '@/assets/subCheckmarkStarter.svg';

import { SubscriptionType } from '@/hooks/IsPickedPlan';
import { Discount, Product } from '@/redux/dto/user';
import { AVAILABLE_SUBSCRIPTIONS } from '@/utils/constants';

import useSubscriptionCard from './useSubscriptionCard';

type Props = {
  label: string;
  type: string;
  active?: string;
  variant?: 'default' | 'minimal' | 'onboarding';
  canceledDate?: string;
  discount?: Discount;
  status?: string | undefined;
  setActive?: (value: SubscriptionType) => void;
  daysLeft?: string;
  description?: string;
  price?: number;
};

const renderIconSubscription = (plan: string) => {
  switch (plan) {
    case AVAILABLE_SUBSCRIPTIONS.starter:
      return <CardStarterIcon />;
    case AVAILABLE_SUBSCRIPTIONS.pro:
      return <CardBasicIcon />;
    case AVAILABLE_SUBSCRIPTIONS.advanced:
      return <CardAdvancedIcon />;
    case AVAILABLE_SUBSCRIPTIONS.advancedPlus:
      return <CardAdvancedPlus />;
    case 'contractor-base':
      return <></>;
    default:
      return <CardBasicIcon />;
  }
};

const renderDescription = (plan: string) => {
  switch (plan) {
    case AVAILABLE_SUBSCRIPTIONS.starter:
      return 'Ideal for small businesses handling up to 2 employees.';
    case AVAILABLE_SUBSCRIPTIONS.pro:
      return 'Ideal for small businesses handling up to 5 employees.';
    case AVAILABLE_SUBSCRIPTIONS.advanced:
      return 'Ideal for small businesses handling up to 15 employees.';
    case AVAILABLE_SUBSCRIPTIONS.advancedPlus:
      return 'Ideal for small businesses handling up to 25 employees.';
    case 'contractor-base':
      return 'You are on a legacy plan. You can update anytime to our new plans';
    default:
      return 'Ideal for small businesses handling up to 15 employees.';
  }
};

const isProductInDiscount = (products: Product[], targetType: SubscriptionType) => {
  if (products.length === 0) {
    return true;
  }

  for (const product of products) {
    if (product.name === targetType) {
      return true;
    }
  }

  return false;
};

export const SubscriptionCard = ({
  label = '',
  type,
  active,
  variant = 'default',
  canceledDate,
  status,
  discount,
  setActive,
  daysLeft,
  description = '',
  price,
}: Props) => {
  const {
    activeSubscriptionAgreement,
    calcDaysUntilCancellation,
    calcPriceWithDiscount,
    handleCardClick,
    trialDays,
    planPrice,
    renderPrice,
  } = useSubscriptionCard({ discount, type, daysLeft, price });

  const renderStatus = (status: string) => {
    switch (status) {
      case 'active':
        return (
          <span className="inline-flex items-center rounded-full bg-teal-100 px-3 py-0.5 text-center font-F37Bolton-Medium text-sm text-teal-700">
            Active
          </span>
        );
      case 'scheduledToBeCancelled':
        return (
          <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-center font-F37Bolton-Medium text-sm text-[#FA6B32]">
            Canceled - {calcDaysUntilCancellation()}
          </span>
        );
      case 'canceled':
        return (
          <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-center font-F37Bolton-Medium text-sm text-[#FA6B32]">
            Canceled
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center rounded-[4px] bg-blue-50 px-3 py-0.5 text-center font-F37Bolton-Medium text-sm text-blue-500">
            {status}
          </span>
        );
    }
  };

  if (variant === 'onboarding') {
    return (
      <div
        className="relative rounded-[12px] border border-blue-500 bg-white px-[16px] py-[12px] shadow-md hover:cursor-pointer"
        onClick={() => {
          handleCardClick(type);
          if (setActive) {
            setActive(type);
          }
        }}
      >
        <div>
          <div className="flex w-full justify-between">
            <span className="flex">
              {renderIconSubscription(type)}
              <span className="ml-1 text-lg capitalize">{label}</span>
            </span>
            <span className={`${type === active ? 'text-[#0074FF]' : ''}`}>
              {(discount && isProductInDiscount(discount.products, type as SubscriptionType) && (
                <div className="flex flex-col">
                  <span className="flex text-blue-500">
                    <span className="mr-1 text-blue-400 line-through">
                      {planPrice ? `$${planPrice}` : ''}
                    </span>
                    ${calcPriceWithDiscount()?.toFixed(2)}
                  </span>
                </div>
              )) ||
                renderPrice()}
            </span>
          </div>
          <div>
            <span className="block py-1 text-[#3D4650]">{renderDescription(type)}</span>
            <span className="block text-sm text-[#A1A6AC]">Pay stubs, W2 and 1099 tax forms</span>
            <span className="mt-2 inline-flex items-center rounded-full bg-[#008C70] px-4 py-0.5 text-center text-xs uppercase text-[#fff]">
              {trialDays} days free trial
            </span>
            {active === type && (
              <span className="absolute bottom-2 right-2 block size-5 rounded-full border-4 border-blue-500 bg-[#fff]"></span>
            )}
          </div>
        </div>
      </div>
    );
  }

  const getPrice = () => {
    if (variant !== 'default' || discount !== undefined) return null;

    if (price) return `$ ${price} / mo.`;

    if (activeSubscriptionAgreement?.price?.amount) {
      return `$ ${activeSubscriptionAgreement.price.amount} / mo.`;
    }
    return renderPrice();
  };

  return (
    <div
      className={`relative mt-[20px] flex w-full items-center rounded-[12px] border bg-white px-[16px] py-[12px] hover:cursor-pointer lg:max-w-none ${
        type === active ? 'border-[#0074FF] shadow-[3px_3px_0px_#C4C8CB]' : ''
      }`}
      onClick={() => {
        handleCardClick(type);
        if (setActive) {
          setActive(type);
        }
      }}
    >
      <div className="self-start">{renderIconSubscription(type)}</div>
      <div className="ml-[8px] w-full">
        <div className="flex w-full justify-between">
          <span className="font-F37Bolton-Medium capitalize">{label}</span>
          {getPrice()}
        </div>
        <span className="block py-1 text-[#3D4650]">{description || renderDescription(type)}</span>
        {status && renderStatus(status)}
        {variant === 'minimal' && type === active && (
          <div className="flex flex-row">
            <span className="font-F37Bolton-Medium text-blue-500">{trialDays} days free trial</span>
            <span className="ml-1 text-blue-400">
              (then{' '}
              <span>{type === active ? (price || planPrice ? `$${planPrice}` : '') : ''}</span>/mo.)
            </span>
          </div>
        )}
        {active && type === active && discount && isProductInDiscount(discount.products, type) && (
          <div className="flex flex-col">
            <span className="font-F37Bolton-Medium text-blue-500">{trialDays} days free trial</span>
            <span className="text-blue-500">
              then{' '}
              <span className="mr-1 text-blue-400 line-through">
                {planPrice ? `$${planPrice}` : ''}
              </span>
              ${calcPriceWithDiscount()?.toFixed(2)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionCard;
