import { useMutation } from '@tanstack/react-query';

import { patchCall } from '@/api/axios';
import { UseSaveOnboardingCustomization } from './types';
import { SAVE_ACCOUNT_DETAILS } from './dashboardApiRoutes';

const useSaveOnboardingCustomization = ({
  accountId,
  mutationParams,
}: UseSaveOnboardingCustomization) => {
  const saveAccountDetails = (payload: any) => {
    return patchCall(SAVE_ACCOUNT_DETAILS({ accountId }), payload);
  };

  return useMutation({
    mutationKey: ['saveAccountDetails'],
    mutationFn: saveAccountDetails,
    ...mutationParams,
  });
};

export default useSaveOnboardingCustomization;
