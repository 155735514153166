import { Outlet, useLocation } from 'react-router-dom';

import { Header, SideBarDesk as SideBar } from '@/components';
import * as routes from '@/routes/routes';

const DashBoardLayout = () => {
  const location = useLocation();

  const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

  // These routes have new design with white background
  const updatedDesignRoutes = [
    routes.DASHBOARD,
    routes.TAX_LIABILITY,
    routes.TAX_LIABILITY_REPORT.replace(':scheduleType', ''),
    routes.COMPANY,
    routes.TEAM_MEMBERS,
    routes.RUN_PAYROLL,
    routes.PAY_CONTRACTORS,
    routes.COMPANY,
    routes.PAYROLL_SETTINGS,
  ];

  return (
    <div className="h-screen">
      <div className="h-[56px]">
        <Header />
      </div>
      <div className={`flex h-[calc(100vh-56px)] ${isAppModeDemo && 'pt-[72px]'}`}>
        <SideBar />
        <div className="flex w-full flex-1 flex-col">
          <main
            className={`relative flex w-full flex-1 flex-col lg:left-[270px] lg:max-w-[calc(100%-270px)] ${
              updatedDesignRoutes.some(item => location.pathname.includes(item))
                ? 'bg-white'
                : 'bg-light-brown'
            }`}
          >
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};
export default DashBoardLayout;
