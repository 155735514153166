import { FC, FunctionComponent, SVGProps } from 'react';
import { ButtonProps, PressEvent, Button as ReactAriaButton } from 'react-aria-components';

import { extractEventName } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

export interface AppButtonProps extends ButtonProps {
  /**
   * The variant of the button component.
   * @default primary
   */
  variant: 'primary' | 'secondary' | 'tertiary' | 'icon' | 'text' | 'light' | 'default';
  /**
   * The height of the button.
   */
  height?: string;
  /**
   * The width of the button.
   */
  width?: string;
  /**
   * Indicates if the button is in a loading state.
   * @default false
   */
  isLoading?: boolean;
  /**
   * An optional icon component to be displayed inside the button.
   */
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  /**
   * Additional CSS classes for the button.
   */
  parentClasses?: string;
  /**
   * Additional CSS classes for the icon.
   */
  iconClasses?: string;
}

const Button: FC<AppButtonProps> = ({
  children,
  variant = 'primary',
  isDisabled,
  isLoading = false,
  height,
  width,
  icon: IconComponent,
  parentClasses,
  ...rest
}) => {
  const variants = {
    primary: `text-base text-white font-normal ${
      isDisabled ? ' bg-emerald-200' : 'bg-emerald-500 hover:bg-emerald-600'
    }`,
    default: `text-base bg-gray-100  hover:bg-gray-200 font-normal ${
      isDisabled ? ' bg-black' : 'text-black'
    }`,
    secondary: `text-white ${
      isDisabled ? 'bg-darkblue-200' : 'bg-darkblue-500 hover:bg-darkblue-600'
    }`,
    tertiary: `border-gray-400 text-gray-400 border ${
      isDisabled && 'border-darkblue-300 text-darkblue-300'
    }`,
    light: `bg-gray-100  hover:bg-gray-200  ${isDisabled ? '!text-gray-200' : '!text-gray-400'}`,
    icon: `border-none block w-auto`,
    text: `border-none block w-auto`,
  };
  const buttonClass = `F37Bolton-Regular flex focus:outline-none items-center justify-center rounded-lg ${
    variants[variant]
  } ${isDisabled && 'cursor-default opacity-50'} ${height ?? 'h-[48px]'} ${width ?? 'w-[335px]'} ${
    isLoading && 'scale-x-[-1]'
  } ${parentClasses}`;

  const handleOnPress = (e: PressEvent) => {
    trackEvent('buttonClick', {
      name: typeof children === 'string' ? children : 'Button',
      currentPage: extractEventName(location.pathname),
    });
    if (rest.onPress) {
      rest.onPress(e);
    }
  };

  if (isLoading) {
    return (
      <ReactAriaButton className={buttonClass} {...rest} isDisabled>
        <img
          src={
            'https://uploads-ssl.webflow.com/63d39d61533099307dbc0bf8/65aa44d7066305c0d7275c1a_Subtract.png'
          }
          alt="loader"
          className="size-6 animate-reverse-spin"
        />
      </ReactAriaButton>
    );
  }

  return (
    <ReactAriaButton
      className={buttonClass}
      isDisabled={isDisabled}
      {...rest}
      onPress={handleOnPress}
    >
      <>
        {IconComponent && <IconComponent />}
        {children}
      </>
    </ReactAriaButton>
  );
};

export default Button;
