import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';

import { ReactComponent as ChevronRightIcon } from '@/assets/chevronRight.svg';
import { ReactComponent as PayPallLogoColor } from '@/assets/paypalColorLogo.svg';
import { ReactComponent as StripeLogo } from '@/assets/stripeLogo1.svg';
import { UnsavedDataConfirmationModal } from '@/components';
import Button from '@/components/Button/Button';
import Drawer from '@/components/ui/Drawer/Drawer';

import useAddNewPaymentMethod from './useAddNewPaymentMethod';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AddNewPaymentMethodDrawer: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const {
    onClickPayPall,
    handleCardCvcChange,
    handleCardExpiryChange,
    handleCardNumberChange,
    step,
    setStep,
    isLoading,
    isCardCvcValid,
    isCardExpiryValid,
    isCardNumberValid,
    errorMessageStripe,
    createPurchaseStripe,
    handleCloseModal,
    cancelUnsavedChangesHandler,
    confirmUnsavedChangesHandler,
    isConfirmationModalOpen,
  } = useAddNewPaymentMethod({ onClose });

  return (
    <>
      <Drawer
        open={isOpen}
        title={step === 1 ? ' Add payment method' : 'Add credit or debit card'}
        onClose={handleCloseModal}
        modelClasses="max-w-[640px]"
      >
        <div className=" flex  w-full  justify-center overflow-y-auto overflow-x-hidden pt-6 lg:h-auto lg:rounded-2xl ">
          {step === 1 ? (
            <div className="flex h-[85vh] w-full flex-col items-start lg:h-auto">
              <div className="my-5 w-full space-y-3">
                <div
                  onClick={() => onClickPayPall()}
                  className="flex  w-full cursor-pointer  flex-col justify-center rounded-2xl border  p-4  "
                >
                  <div className="flex items-center ">
                    <div className="flex h-8 w-full items-center justify-start">
                      <div className="mr-2  flex items-center justify-center rounded-md border   ">
                        <PayPallLogoColor className="h-full w-[25px]" />
                      </div>
                      <div className="w-full font-F37Bolton-Medium text-[16px] leading-normal text-black">
                        PayPal
                      </div>
                      <ChevronRightIcon />
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => setStep(2)}
                  className=" flex  w-full cursor-pointer flex-col justify-center  rounded-2xl border  p-4 "
                >
                  <div className="flex items-center ">
                    <div className="flex h-8 w-full items-center justify-start">
                      <div className="mr-2  flex items-center justify-center rounded-sm bg-surface-grey p-[4px]">
                        <StripeLogo />
                      </div>
                      <div className="w-full font-F37Bolton-Medium text-[16px] leading-normal text-black">
                        Stripe <span>(Card)</span>
                      </div>
                      <ChevronRightIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex  w-full flex-col items-start ">
              <div className=" w-full space-y-3">
                <div className=" gap-4 space-y-4  ">
                  <div className="space-y-2">
                    <label>Card number</label>
                    <CardNumberElement
                      onChange={handleCardNumberChange}
                      options={{
                        classes: {
                          empty: 'border-gray-300',
                          base: 'text-xl bg-white border rounded-md  border-gray-300 text-gray-900 items-center py-[10px] px-[12px]',
                          invalid: 'border-red border',
                        },
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <label>Expiration date</label>
                      <CardExpiryElement
                        onChange={handleCardExpiryChange}
                        options={{
                          classes: {
                            base: 'bg-white border rounded-md border-gray-300 text-gray-900 items-center py-[10px] px-[12px]',
                          },
                        }}
                      />
                    </div>

                    <div className="space-y-2">
                      <label>Security code</label>

                      <CardCvcElement
                        onChange={handleCardCvcChange}
                        options={{
                          classes: {
                            base: 'bg-white border rounded-md border-gray-300 text-gray-900 items-center py-[10px] px-[12px]',
                          },
                        }}
                      />
                    </div>
                  </div>
                  {errorMessageStripe && (
                    <span className="flex h-auto w-full flex-col items-start text-sm text-red-500">
                      {errorMessageStripe}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className=" absolute bottom-0 flex w-full justify-between gap-3 border-t  p-5">
              <Button
                variant="light"
                children="Back"
                width="w-full max-w-fit py-3 px-6"
                onPress={handleCloseModal}
              />

              <Button
                isLoading={isLoading}
                variant="primary"
                type="submit"
                children="Add card"
                width="w-[120px] py-3 px-6"
                isDisabled={!isCardNumberValid || !isCardExpiryValid || !isCardCvcValid}
                onPress={() => createPurchaseStripe()}
              />
            </div>
          )}
        </div>
      </Drawer>
      <UnsavedDataConfirmationModal
        isOpen={isConfirmationModalOpen}
        onCancel={cancelUnsavedChangesHandler}
        onConfirm={confirmUnsavedChangesHandler}
      />
    </>
  );
};

export default AddNewPaymentMethodDrawer;
