import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TabType } from '@/components/ui/TabsComponent/types';
import useTabs from '@/components/ui/TabsComponent/useTabs';
import { Dispatch, RootState } from '@/redux/store';
import { AccountBasicInfo, BillingCenter } from './components';
import { UseMyAccountType } from './types';
import { MY_ACCOUNT_TAB_IDS } from './utils/constants';

const useMyAccount = ({ onGotoDashboard }: UseMyAccountType) => {
  const dispatch = useDispatch<Dispatch>();

  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState<TabType[]>([]);

  const tabController = useTabs({
    tabs,
  });

  const initialTabSetup: TabType[] = useMemo(
    () => [
      {
        id: MY_ACCOUNT_TAB_IDS.myProfile,
        title: 'My Profile',
        disabled: false,
        component: AccountBasicInfo,
        props: {},
        isCompleted: false,
      },
      {
        id: MY_ACCOUNT_TAB_IDS.accountBilling,
        title: 'Billing center',
        disabled: false,
        component: BillingCenter,
        props: {
          onGotoDashboard,
        },
        isCompleted: false,
      },
    ],
    [],
  );

  useEffect(() => {
    setTabs(initialTabSetup);
  }, [initialTabSetup]);

  const subscriptionInformation = useSelector((state: RootState) => state.auth.subscriptionInfo);

  const getSubscriptionStatus = async () => {
    await dispatch.auth.getSubscriptionStatus();
    setIsLoading(false);
  };

  useEffect(() => {
    if (subscriptionInformation !== null) {
      getSubscriptionStatus();
    } else {
      setIsLoading(true);
      getSubscriptionStatus();
    }
  }, []);

  const fetchSubscriptionPlan = async () => {
    await dispatch.auth.getSubscriptionPlan();
  };

  useEffect(() => {
    fetchSubscriptionPlan();
  }, []);

  return {
    subscriptionInformation,
    isLoading,
    tabController,
  };
};

export default useMyAccount;
