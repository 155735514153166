import { useAuth0 } from '@auth0/auth0-react';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import CobrowseIO from 'cobrowse-sdk-js'

import { Dispatch, RootState } from '@/redux/store';
import * as routes from '@/routes/routes';
import { useLocationEffect } from '@/utils/helpers';

const PrivateRoute = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const growthbook = useGrowthBook();

  const account = useSelector((state: RootState) => state?.auth?.user?.account);

  const subscriptionAgreements = useSelector(
    (state: RootState) => state.auth?.subscriptionInfo?.subscriptionAgreements,
  );

  const hasSubscriptions = Array.isArray(subscriptionAgreements) && !!subscriptionAgreements.length;

  const authErrMessage = useSelector((state: RootState) => state.auth?.error?.error);

  const { logout } = useAuth0();

  const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

  useEffect(() => {
    // Just forcing a refresh of identity, otherwise it only happens on /callback
    if (!isAppModeDemo) {
      dispatch.auth.getUserInformation();
      dispatch.auth.getOnboardinUserStatus();
    }
  }, []);

  useEffect(() => {
    if (account?.id) {
      growthbook?.setAttributes({
        userId: account?.id,
        auth0id: account?.auth0Id,
        email: account?.email,
      });
    }
  }, [account]);

  useLocationEffect();

  useEffect(() => {
    if (import.meta.env.APP_MODE !== 'DEMO') {
      if (!hasSubscriptions) {
        navigate(routes.CALLBACK);
      }
      if (authErrMessage === 'login_required') {
        localStorage.clear();
        logout({
          logoutParams: {
            returnTo: window.location.origin + '/login',
          },
        });
        navigate(routes.LOGOUT);
        window.fcWidget?.user.clear();
      }
    }
  }, [hasSubscriptions, authErrMessage]);

  if (import.meta.env.APP_MODE === 'DEMO') {
    return <Outlet />;
  }

  return hasSubscriptions ? <Outlet /> : <></>;
};

export default PrivateRoute;
