import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from '@headlessui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '@/assets/logo.svg';
import { ReactComponent as SidebarIcon } from '@/assets/sidebar.svg';
import { ReactComponent as XMarkWhite } from '@/assets/xmarkWhite.svg';
import { Button, LogoutModal } from '@/components';
import { RootState } from '@/redux/store';
import * as routes from '@/routes/routes';
import { navigation } from '@/utils/constants';
import { keyUp } from '@/utils/helpers';
import UserAvatar from '../UserAvatar';

export default function Header() {
  const navigate = useNavigate();
  const { logout, user } = useAuth0();
  const fullName = useSelector(
    (state: RootState) =>
      state.auth.onboardingInfo?.account?.firstName +
      ' ' +
      state.auth.onboardingInfo?.account?.lastName,
  );
  const email = useSelector((state: RootState) => state.demo.demoInfo.email);
  const businessRole = useSelector((state: RootState) => state.demo.demoInfo.businessRole);
  const numberOfEmployees = useSelector(
    (state: RootState) => state.demo.demoInfo.numberOfEmployees,
  );
  const numberOfContractors = useSelector(
    (state: RootState) => state.demo.demoInfo.numberOfContractors,
  );
  const otherRole = useSelector((state: RootState) => state.demo.demoInfo.otherBusinessRole);

  const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [isOpenedLogoutModal, setisOpenedLogoutModal] = useState<boolean>(false);

  const currentPath = location.pathname;

  const handleLogout = async () => {
    try {
      localStorage.clear();
      logout();
      window.fcWidget.user.clear().then(
        function () {
          console.log('FcWidget User cleared');
        },
        function () {
          console.error('FcWidget User Not cleared');
        },
      );
      window.FreshworksWidget('destroy');
    } catch (error) {
      alert(error);
    }
  };

  return (
    <header className="fixed z-[45] w-full">
      {isAppModeDemo && (
        <div className="flex h-[72px] w-full items-center justify-between bg-[#EBF3F0] px-[10px] lg:px-[40px]">
          <span className="hidden font-F37Bolton-Medium text-[20px] lg:block">
            This is just a demo, create an account and run payroll for free for 30 days.
          </span>
          <span className="block max-w-[160px] font-F37Bolton-Medium text-[18px] lg:hidden">
            This is just a demo, try it out for real.
          </span>
          <a
            href={`https://app.paystubs.com/signup?we=${email}&role=${businessRole}&employees=${numberOfEmployees}&contractors=${numberOfContractors}&otherRole=${otherRole}`}
            className="flex h-[48px] w-[185px] items-center justify-center rounded-[8px] bg-emerald-500 text-center text-white hover:bg-emerald-600 lg:w-[250px]"
          >
            Start 30-Day Free Trial
          </a>
        </div>
      )}

      <div className="flex w-full items-center justify-between bg-darkblue-500">
        <nav
          className="flex h-[56px] w-full max-w-7xl items-center justify-between py-[14px] lg:w-3/4 lg:px-8"
          aria-label="Global"
        >
          <div className="flex pl-5 lg:flex-1">
            <Link to={routes.DASHBOARD} className="">
              <Logo />
            </Link>
          </div>
          <div className="flex pr-5 lg:hidden">
            {!mobileMenuOpen ? (
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => {
                  setMobileMenuOpen(!mobileMenuOpen);
                }}
              >
                <span className="sr-only">Open main menu</span>
                <SidebarIcon />
              </button>
            ) : (
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => {
                  setMobileMenuOpen(!mobileMenuOpen);
                }}
              >
                <span className="sr-only">Close menu</span>
                <XMarkWhite />
              </button>
            )}
          </div>
        </nav>
        <div
          onClick={() => navigate(routes.MY_ACCOUNT)}
          className="hidden w-1/4 cursor-pointer pr-[40px] text-white lg:flex lg:items-center lg:justify-end"
        >
          <UserAvatar height={40} width={40} src={user?.picture} />
          <span className="ml-[12px]">
            {fullName === 'undefined undefined' ? 'User' : fullName}
          </span>
        </div>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="sm:ring-gray-900/10 fixed inset-y-0 right-0 z-40 w-full overflow-y-auto bg-light-brown sm:max-w-sm sm:ring-1">
            <nav
              className="flex h-[56px] w-full max-w-7xl items-center justify-between bg-darkblue-500 py-[14px] lg:w-3/4 lg:px-8"
              aria-label="Global"
            >
              <div className="flex pl-5 lg:flex-1">
                <Link to={routes.DASHBOARD} className="">
                  <Logo />
                </Link>
              </div>
              <div className="flex pr-5 lg:hidden">
                {!mobileMenuOpen ? (
                  <button
                    type="button"
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    onClick={() => {
                      setMobileMenuOpen(!mobileMenuOpen);
                    }}
                  >
                    <span className="sr-only">Open main menu</span>
                    <SidebarIcon />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="rounded-md text-gray-700"
                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkWhite />
                  </button>
                )}
              </div>
            </nav>
            <div>
              <div>
                <div>
                  {navigation.map((item, index) => {
                    const isCurrent = item.path === currentPath;
                    if (item.label) {
                      return (
                        <span
                          key={index}
                          className="ml-[40px] mr-[12px] flex pb-[15px] pt-[20px] text-sm text-gray-400"
                          aria-hidden="true"
                        >
                          {item.name}
                        </span>
                      );
                    }
                    return (
                      <Link
                        key={item.path}
                        to={item.path}
                        onClick={() => setMobileMenuOpen(false)}
                        className={`
                        ${
                          isCurrent
                            ? 'border-l-[3px] border-blue-500 text-blue-500'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                        }
                        items-center', group mt-[10px] flex h-[44px]
                      `}
                      >
                        <item.icon
                          className={`
                        ${
                          isCurrent
                            ? 'ml-[37px] text-blue-500'
                            : 'text-gray-400 group-hover:text-gray-500'
                        }
                          ml-[40px] mr-[12px] h-6 w-6
                        `}
                          aria-hidden="true"
                        />
                        <span className="flex-1">{item.name}</span>
                      </Link>
                    );
                  })}
                </div>
                <div className="mb-[10px] mt-[50px]">
                  <div className="flex flex-col items-start justify-center pb-[30px]">
                    <div
                      className="ml-[40px] mt-[40px] flex h-[44px] items-center menu-height:static"
                      onKeyUp={keyUp}
                      tabIndex={0}
                      role="button"
                    >
                      <Button
                        type={'logout'}
                        isDisabled={isAppModeDemo}
                        onClick={() => setisOpenedLogoutModal(!isOpenedLogoutModal)}
                      />
                    </div>
                    <span className="ml-[43px] flex h-[44px] items-center  menu-height:static">
                      <span
                        onKeyUp={keyUp}
                        tabIndex={0}
                        role="button"
                        className="mr-[5px] text-blue-500 hover:cursor-pointer hover:text-blue-700"
                        onClick={() =>
                          window.open(
                            'https://www.paystubs.com/legal/terms-and-conditions',
                            '_blank',
                          )
                        }
                      >
                        T&C
                      </span>{' '}
                      and{' '}
                      <span
                        onKeyUp={keyUp}
                        tabIndex={0}
                        role="button"
                        className="ml-[5px] text-blue-500 hover:cursor-pointer hover:text-blue-700"
                        onClick={() =>
                          window.open('https://www.paystubs.com/legal/privacy-policy', '_blank')
                        }
                      >
                        Privacy Policy
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
        {isOpenedLogoutModal && (
          <LogoutModal
            isOpen={isOpenedLogoutModal}
            toggleModal={isOpen => setisOpenedLogoutModal(isOpen)}
            onAction={handleLogout}
          />
        )}
      </div>
    </header>
  );
}
