import PublicHeader from '@/components/shared/Header/PublicHeader';
import { CustomizationModal } from '@/modules/auth/modals';

import GetStart from './components/GetStart/GetStart';
import WhatTodo from './components/WhatToDo/WhatToDo';
import useAccountSetup from './useAccountSetup';

const AccountSetup = () => {
  const {
    shouldShowCustomizationModal,
    customizationModalCancelHandler,
    onSaveCustomizationHandler,
    customizationOptions,
    isSavingCustomization,
    firstName,
  } = useAccountSetup();

  return (
    <>
      <PublicHeader showProfile />
      <div className="flex flex-col lg:flex-row">
        <GetStart firstName={firstName} />
        <WhatTodo />
      </div>
      <CustomizationModal
        isOpen={shouldShowCustomizationModal}
        onCancel={customizationModalCancelHandler}
        onSaveClickHandler={onSaveCustomizationHandler}
        options={customizationOptions}
        isSaving={isSavingCustomization}
      />
    </>
  );
};

export default AccountSetup;
