/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import StripeAndPaypalSignUp from '@/modules/auth/components/StripeAndPaypal';
import { Dispatch, RootState } from '@/redux/store';
import { AVAILABLE_SUBSCRIPTIONS, TRIAL_DAYS, subscriptionCardsInfo } from '@/utils/constants';
import { callToast } from '@/utils/helpers';

import SubscriptionCardGroup from '../SubscriptionCardGroup';

import { SubscriptionType } from '@/hooks/IsPickedPlan';

interface SubscriptionCardGroupProps {
  description?: string;
  buttonText?: string;
  isLoading?: boolean;
  activePlan?: string;
  planDescription?: boolean;
}

const SubscriptionFreeContainer: React.FC<SubscriptionCardGroupProps> = ({
  buttonText,
  description = 'You will not be charged, and can cancel at any time',
  planDescription = true,
  activePlan = AVAILABLE_SUBSCRIPTIONS.pro,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionType | any>(
    AVAILABLE_SUBSCRIPTIONS.pro,
  );

  const email = useSelector((state: RootState) => state?.auth?.user?.account?.email);
  const userId = useSelector((state: RootState) => state?.auth?.user?.account?.id);
  const subscriptionInformation = useSelector((state: RootState) => state.auth.subscriptionInfo);

  const trialDays =
    useSelector((state: RootState) => state?.auth?.callbackOnboardingInfo?.trialDays) ?? TRIAL_DAYS;

  const formattedButtonText = buttonText ?? `Start ${trialDays}-day free trial`;

  const hasSubscriptionAgreements = subscriptionInformation?.subscriptionAgreements?.length;

  const shouldRenderStripe = !(
    hasSubscriptionAgreements && !subscriptionInformation?.activeSubscriptionAgreement
  );

  const fastSpringCallBack = () => {
    if (window.fastspring) {
      const session = {
        reset: true,
        products: subscriptionCardsInfo.map(item => {
          return {
            path: item.type,
            quantity: item.type === selectedPlan ? 1 : 0,
          };
        }),
        paymentContact: {
          email: email,
        },
        tags: {
          customerId: userId,
        },
        language: 'en',
      };
      window.fastspring.builder.push(session);
    }
  };

  useEffect(() => {
    if (window.fastspring) {
      fastSpringCallBack();
    }
  }, [selectedPlan]);

  const addSBL = () => {
    const scriptId = 'fsc-api';
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
      script(scriptId);
    }
  };

  useEffect(() => {
    addSBL();
    return () => {
      window.fastspring?.builder.clean();
      document.getElementById('fsc-api')?.remove();
    };
  }, []);

  function dataPopupWebhookReceived(data: any) {
    if (!data.items[0]?.subscription) {
      callToast('error', 'Something went wrong, please try again later');
      return;
    }

    dispatch.auth.verifyFsSubscription(data.items[0]?.subscription);
  }

  const script = (scriptId: string) => {
    const fastSpringStorefront = import.meta.env.APP_FASTSPRING_STOREFRONT;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = scriptId;
    script.src = 'https://sbl.onfastspring.com/sbl/0.9.5/fastspring-builder.min.js';
    script.dataset.storefront = fastSpringStorefront;
    window.callbackReceivePurchase = dataPopupWebhookReceived;
    script.setAttribute('data-popup-webhook-received', 'callbackReceivePurchase');
    script.onload = function () {
      fastSpringCallBack();
    };
    document.head.appendChild(script);
  };
  const onPurchase = async (paymentMethodId: string, type: SubscriptionType) => {
    setIsLoading(true);
    await dispatch.auth.createPaymentMethodStripe({ paymentMethodId, email });
    await dispatch.auth.createSubscriptionAgreement({
      product: activePlan ? activePlan : AVAILABLE_SUBSCRIPTIONS.starter,
      coupon: null,
    });
    await dispatch.auth.getSubscriptionStatus();
    setIsLoading(false);
  };

  const onPurchasePayPall = async (type: string) => {
    setIsLoading(true);
    const response = await dispatch.auth.setupPayPallToken();
    await dispatch.auth.savePickedPlan({ type });

    window.location.assign(response.approveUrl);
    setIsLoading(false);
  };

  return (
    <div className="max-w-[335px] overflow-hidden xl:max-w-[700px]">
      {shouldRenderStripe && (
        <h1 className="mb-2 font-F37Bolton-Medium text-2xl text-gray-900">
          Your first {trialDays} days are for free!
        </h1>
      )}
      <span className="mb-5 text-base text-gray-500">
        To use the service, you need to choose a subscription plan
      </span>
      {!hasSubscriptionAgreements || shouldRenderStripe ? (
        <>
          <SubscriptionCardGroup
            description={description}
            buttonText={formattedButtonText}
            activePlan={selectedPlan}
            onPurchasePayPall={onPurchasePayPall}
            onPurchase={(paymentMethodId, type) => onPurchase(paymentMethodId, type)}
            isLoading={isLoading}
            planDescription={planDescription}
            setSelectedPlan={setSelectedPlan}
          />
          <div id="fsc-embedded-checkout-container" className="relative min-h-[200px] w-full"></div>
          <div className="text-center">
            <span className="mt-[35px] max-w-[335px] text-center text-sm text-[#4D4D4D]">
              Didn’t find what you’re looking for?{' '}
              <span
                className=" text-[#0074FF] underline hover:cursor-pointer"
                onClick={() => window.fcWidget.open()}
              >
                Chat with support
              </span>{' '}
              to request a custom subscription plan
            </span>
          </div>
        </>
      ) : (
        <StripeAndPaypalSignUp isTrial={!hasSubscriptionAgreements || shouldRenderStripe} />
      )}
    </div>
  );
};

export default SubscriptionFreeContainer;
