import { Transition } from '@headlessui/react';
import React, { useState } from 'react';

import { ConfirmationActionModal } from '@/components';
import Button from '@/components/Button/Button';
import useClickOutside from '@/hooks/clickOutside';

interface OptionsDropdownProps {
  onDelete: () => void;
  onSetDefault: () => void;
}

const ActionPaymentMethod: React.FC<OptionsDropdownProps> = ({ onDelete, onSetDefault }) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useClickOutside(() => setIsOpen(false));

  const handleDelete = () => {
    setIsDeleteOpen(!isDeleteOpen);
    setIsOpen(false);
  };

  const handleSetDefault = () => {
    onSetDefault();
    setIsOpen(false);
  };

  const handleDeleteModel = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const handleDeletePaymentMethod = () => {
    onDelete();
    setIsDeleteOpen(!isDeleteOpen);
  };

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <Transition
        show={isOpen}
        className="relative z-20"
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="z-index-10 absolute right-0  top-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <button
              type="button"
              className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              onClick={handleSetDefault}
            >
              Set as default
            </button>
            <button
              type="button"
              className="block w-full px-4 py-2 text-left text-sm text-red-500 hover:bg-gray-100 hover:text-red-500"
              onClick={handleDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </Transition>
      <div className=" relative z-0">
        <button
          type="button"
          className="flex h-9 w-9 flex-col items-center justify-center space-y-1 rounded-full bg-white ring-transparent hover:bg-gray-100 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="h-1 w-1 rounded-full bg-gray-200"></span>
          <span className=" h-1 w-1 rounded-full bg-gray-200"></span>
          <span className=" h-1 w-1 rounded-full bg-gray-200"></span>
        </button>
      </div>

      {isDeleteOpen && (
        <ConfirmationActionModal
          onCancel={handleDeleteModel}
          width="sm:w-[480px]"
          modalClasses="sm:px-6 py-8 max-w-[380px]"
          isOpen={isDeleteOpen}
          title="Are you sure you want to remove the payment method ?"
          showCloseIcon={false}
          actions={
            <div className="flex flex-col-reverse items-center justify-center gap-2 sm:flex-row">
              <Button
                variant="tertiary"
                onPress={handleDeleteModel}
                parentClasses="font-F37Bolton-Medium w-full"
              >
                Close
              </Button>
              <Button
                variant="primary"
                onPress={handleDeletePaymentMethod}
                parentClasses="bg-red-600 w-full hover:bg-red-700"
              >
                Delete
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
};

export default ActionPaymentMethod;
