import React from 'react';
import { Controller } from 'react-hook-form';
import InputDecimalsField from '../InputDecimalsField';
import { FormInputDecimalsFieldProps } from './types';

const FormInputDecimalsField: React.FC<FormInputDecimalsFieldProps> = ({
  name,
  isRequired,
  rules,
  isDisabled,
  shouldUnregister = true,
  ...rest
}) => (
  <Controller
    name={name}
    shouldUnregister={shouldUnregister}
    disabled={Boolean(isDisabled)}
    render={({ field: { value, ...restField }, fieldState: { error, isTouched } }) => (
      <InputDecimalsField
        errorMessage={error && !!error && error.message}
        isRequired={Boolean(isRequired || rules?.required)}
        value={value}
        isDisabled={isDisabled}
        {...rest}
        {...restField}
      />
    )}
    rules={rules}
  />
);

export default FormInputDecimalsField;
