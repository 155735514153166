import { Controller } from 'react-hook-form';

import RadioButtonGroup from '@/components/RadioButtonGroup/RadioButtonGroup';
import { FormInputDecimalsField, FormInputTextField } from '@/components/ui';
import FormSelect from '@/components/ui/FormSelect';

import { EmployerTaxInputItemFormProps } from './types';

const EmployerTaxInputItemForm = ({ field, fieldIndex, form }: EmployerTaxInputItemFormProps) => {
  const { type, description, regex, choices, info } = field;

  if (type === 'Percentage') {
    return (
      <FormInputDecimalsField
        rules={{
          required: `Please fill in this ${description}`,
          max: {
            value: 100,
            message: `${description} value is invalid`,
          },
        }}
        name={`fields[${fieldIndex}].value`}
        title={description}
        isRequired
        InputAdornment={'%'}
        decimalsLimit={3}
        placeholder="0.00"
        allowNegativeValue={false}
        step={0.01}
        decimalSeparator="."
        min={0}
        max={100}
        handleValueCheck={value => (Number(value) >= 0 && Number(value) <= 100 ? value : null)}
        description={info ?? ''}
      />
    );
  }
  if (type === 'Money') {
    return (
      <FormInputDecimalsField
        rules={{ required: `Please fill in this ${description}` }}
        name={`fields[${fieldIndex}].value`}
        title={description}
        isRequired
        InputAdornment="$"
        description={info ?? ''}
      />
    );
  }
  if (type === 'String') {
    return (
      <FormInputTextField
        rules={{ required: `Please fill in this ${description}` }}
        name={`fields[${fieldIndex}].value`}
        title={description}
        isRequired
        subtitle={info ?? ''}
      />
    );
  }
  if (type === 'Integer') {
    return (
      <FormInputDecimalsField
        name={`fields[${fieldIndex}].value`}
        title={description}
        isRequired
        rules={{
          pattern: {
            value: new RegExp(regex),
            message: `${description} value is invalid`,
          },
          required: `Please fill in this ${description}`,
        }}
        placeholder="0"
        decimalScale={0}
        allowDecimals={false}
        description={info ?? ''}
      />
    );
  }
  if (type === 'Choice') {
    return (
      <Controller
        name={`fields[${fieldIndex}].value`}
        control={form.control}
        rules={{
          required: `${description} is required`,
          pattern: regex
            ? { value: new RegExp(regex), message: `${description} value is invalid` }
            : undefined,
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <RadioButtonGroup
              options={Array.isArray(choices) ? choices : []}
              label={description}
              onChange={value => {
                onChange(value);
              }}
              selectedValue={value}
              labelClasses="text-base"
              optionWrapperClasses="flex-wrap gap-y-2"
              showAsterisk
              errorMessage={error?.message ?? ''}
              description={info ?? ''}
            />
          );
        }}
      />
    );
  }
  if (type === 'List') {
    const { choices } = field;
    return (
      <FormSelect
        width="w-full"
        title={description}
        rules={{
          required: `${description} is required`,
        }}
        name={`fields[${fieldIndex}].value`}
        options={
          Array.isArray(choices) ? choices.map(({ label, value }) => ({ name: label, value })) : []
        }
        subtitle={info ?? ''}
      />
    );
  }
};

export default EmployerTaxInputItemForm;
