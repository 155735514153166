import { Dialog } from '@headlessui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CardAdvancedIcon } from '@/assets/subCheckmarkAdvanced.svg';
import { ReactComponent as CardBasicIcon } from '@/assets/subCheckmarkBasic.svg';
import { ReactComponent as CardStarterIcon } from '@/assets/subCheckmarkStarter.svg';
import { Button, SubscriptionCard } from '@/components';
import { Dispatch, RootState } from '@/redux/store';
import * as routes from '@/routes/routes';
import {
  AVAILABLE_SUBSCRIPTIONS,
  allPlansIncludes,
  subscriptionCardsInfo,
} from '@/utils/constants';

export interface RenewPlanModalProps {
  isOpen: boolean;
  currentPlan: string;
  maxSeats: number;
  toggleModal: (isOpen: boolean) => void;
  showAllSubscriptions?: boolean;
  onGotoDashboard?: () => void;
}

export const renderIconSubscription = (plan: string) => {
  switch (plan) {
    case AVAILABLE_SUBSCRIPTIONS.starter:
      return <CardStarterIcon className="size-12" />;
    case AVAILABLE_SUBSCRIPTIONS.pro:
      return <CardBasicIcon className="size-12" />;
    case AVAILABLE_SUBSCRIPTIONS.advanced:
      return <CardAdvancedIcon className="size-12" />;
    // case 'advanced_plus':
    //   return <CardAdvancedPlus className="h-12 w-12" />;
    default:
      return <CardAdvancedIcon className="size-12" />;
  }
};

const RenewPlanModal: React.FC<RenewPlanModalProps> = ({
  isOpen,
  currentPlan,
  maxSeats,
  toggleModal,
  onGotoDashboard,
}: RenewPlanModalProps) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const [active, setActive] = useState<string>(currentPlan);
  const [currentStep, setStep] = useState<number>(1);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const selectedPlan =
    subscriptionCardsInfo.find(card => card.type === active) || subscriptionCardsInfo[3];

  const subscriptionInformation = useSelector((state: RootState) => state.auth.subscriptionInfo);

  const id = subscriptionInformation?.activeSubscriptionAgreement?.id;

  const handleSubscribePlan = async () => {
    try {
      setisLoading(true);
      const response = await dispatch.auth.renewSubscription({ id });
      await dispatch.company.getInformationForRunPayroll({});
      response && setStep(2);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      await dispatch.auth.getSubscriptionStatus();
      setStep(1);
    }
  };
  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="relative z-10 mx-2 my-20 max-h-[95vh] w-full max-w-md  overflow-y-auto rounded-lg bg-white sm:mx-auto">
            <div className="flex w-full flex-col items-center justify-center  py-3">
              <div className="my-5">{renderIconSubscription(currentPlan)}</div>
              <div className="flex flex-col text-center">
                <h1 className="mb-2 font-F37Bolton-Medium text-2xl text-gray-900">
                  We’ve missed you!
                </h1>
                <span className="mb-2 text-base text-gray-500">
                  Choose the plan that suites you
                </span>
              </div>
              <div className="mb-4">
                <div className="max-w-[335px]">
                  <SubscriptionCard
                    label={
                      subscriptionCardsInfo.find(
                        card => card.type === subscriptionInformation?.productName,
                      )?.label || AVAILABLE_SUBSCRIPTIONS.starter
                    }
                    canceledDate={
                      subscriptionInformation?.activeSubscriptionAgreement?.canceledDate
                    }
                    active={subscriptionInformation?.productName}
                    type={subscriptionInformation?.productName}
                  />
                </div>
                <div className="mt-[20px]">
                  <Button
                    type="primary"
                    isLoading={isLoading}
                    children="Subscribe"
                    isDisabled={!active}
                    onClick={() => handleSubscribePlan()}
                    width="w-[335px]"
                  />
                </div>
              </div>
              <div className="text-center">
                <Button
                  type={'link'}
                  children="Maybe later"
                  onClick={() => {
                    toggleModal(!isOpen);
                    setStep(1);
                  }}
                />
              </div>
              <div className="mt-5">
                <h1 className="font-F37Bolton-Medium text-lg text-gray-900">
                  All plans included:{' '}
                </h1>
                <ul className="mb-[10px] list-none">
                  {allPlansIncludes.map(item => (
                    <li
                      key={item}
                      className="flex min-h-[40px] w-full min-w-[335px] max-w-[335px] justify-between border-b border-darkblue-100 py-[12px] last:border-none"
                    >
                      <span className="max-w-[250px] text-left">{item}</span>
                      <span className="mr-[20px]">&#x2713;</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="relative z-10 mx-auto max-w-lg rounded-lg bg-white">
            <div className="flex min-h-[400px]  min-w-[350px] flex-col items-center justify-center overflow-y-auto lg:min-w-[400px]">
              <div className="flex w-[300px] flex-col text-center ">
                <div className="flex justify-center p-4">
                  {renderIconSubscription(selectedPlan?.type)}
                </div>
                <h1 className="mb-2 font-F37Bolton-Medium text-2xl text-gray-900">Subscribed!</h1>
                <span className="mb-2 text-base text-gray-500">
                  Congratulations! You can now create pay stubs for up to {maxSeats} team members a
                  month
                </span>
                <div className="w-full">
                  <span className=" text-black">
                    You can view and manage your subscription in the{' '}
                  </span>
                  <span
                    onClick={() => navigate(routes.MY_ACCOUNT_BILLING_CENTER)}
                    className="cursor-pointer text-blue-600 underline "
                  >
                    billing center
                  </span>
                </div>
              </div>
              <div className="my-4 flex flex-col justify-center space-y-2">
                <Button
                  type="primary"
                  children="Go to dashboard"
                  onClick={() => {
                    setStep(1);
                    toggleModal(!isOpen);
                    setActive(undefined);
                    if (onGotoDashboard) onGotoDashboard();
                    else navigate('/dashboard/main');
                  }}
                  width="w-[150px]"
                />
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => null}
      className="fixed inset-0 z-50 flex items-center justify-center"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
      {renderContent()}
    </Dialog>
  );
};

export default RenewPlanModal;
