import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Address } from '@/components/LocationCard/LocationCard';
import { ONBOARDING_STRUCTURE } from '@/components/shared/AccountSetupLayout/constants';
import { Item } from '@/modules/payrollHistory/components/HistoryDetailsItemCard';
import { Account } from '@/redux/dto/auth';
import { HidingAlgorithmType, TaxItem } from '@/redux/dto/company';
import { RunnablePayrolls } from '@/redux/dto/employee';
import { RootState } from '@/redux/store';

import { Toast } from '@/components/ui/alerts/Toast/Toast';
import { sec } from './security';

export type EmployeeSpoilerData = {
  employerTaxes: [
    {
      amount: number;
      name: string;
    },
  ];
  id: number;
  payDate: string;
  payFrequency: number;
  payments: Item[];
  status: number;
  totalDeductions: number;
  totalEmployeeTaxes: number;
  totalEmployerContributions: number;
  totalEmployerTaxes: number;
  totalHours: number;
  totalNetPay: number;
  totalPayroll: number;
  totalReimbursements: number;
  totalWages: number;
};

export const getToken = async () => {
  const accessTokenFromStorage = localStorage.getItem('accessToken');
  if (!accessTokenFromStorage) {
    if (sec.getAccessTokenSilently()) {
      try {
        const accessToken = await sec.getAccessTokenSilently()({
          audience: import.meta.env.APP_AUTH_AUDIENCE,
        });
        return accessToken;
      } catch (e) {
        return null;
      }
    }
  } else {
    return accessTokenFromStorage;
  }
};

export const checkIsEmployeeInvitation = () => {
  return window.location.pathname.includes('invitation');
};

export const getEmployeeInvitationToken = () => {
  return window.location.pathname?.split('/')?.at(-1) ?? null;
};

export const getFullName = (data: { firstName: string; lastName: string }) =>
  data?.firstName && data?.lastName ? data?.firstName + ' ' + data?.lastName : 'Name Surname';

export const callToast = (
  variant: 'success' | 'warning' | 'info' | 'error',
  subtitle: string,
  title?: string | null,
): void => {
  toast(Toast({ variant, title, subtitle }), {
    position: 'top-right',
    style: { backgroundColor: 'transparent' },
  });
};

export const downloadPayStub = (
  response: Blob | MediaSource,
  id: number,
  isPreview = true,
  name = 'PayStubs_',
) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(response);
  link.download = `${name}${id}.pdf`;
  link.dispatchEvent(new MouseEvent('click'));
  isPreview && window.open(link.href);
};

export const downloadDocument = ({
  response,
  isPreview = true,
  name = 'Report',
}: {
  response: Blob | MediaSource;
  isPreview?: boolean;
  name: string;
}) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(response);
  link.download = `${name}.pdf`;
  link.dispatchEvent(new MouseEvent('click'));
  isPreview && window.open(link.href);
};

export const generateSemiMothDisabledDate = (payFrequency: string) => {
  const disabledDatesPayDate = [];
  const disabledDatesPayPeriod = [];

  if (payFrequency === '3') {
    for (let year = 2023; year <= 2023; year++) {
      for (let month = 1; month <= 12; month++) {
        const lastDayOfMonth = new Date(year, month, 0).getDate();

        // Disable all dates except for 15th and last day of each month
        for (let day = 1; day <= lastDayOfMonth; day++) {
          if (day !== 15 && day !== lastDayOfMonth) {
            const date = `${year}-${month.toString().padStart(2, '0')}-${day
              .toString()
              .padStart(2, '0')}`;
            disabledDatesPayDate.push({ startDate: date, endDate: date });
          }
        }
        // Disable all dates except for 1th and 16th
        for (let day = 1; day <= lastDayOfMonth; day++) {
          if (day !== 1 && day !== 16) {
            const date = `${year}-${month.toString().padStart(2, '0')}-${day
              .toString()
              .padStart(2, '0')}`;
            disabledDatesPayPeriod.push({ startDate: date, endDate: date });
          }
        }
      }
    }
  }
  return { disabledDatesPayDate, disabledDatesPayPeriod };
};

export const generateMonthlyDisabledDate = (payFrequency: string) => {
  const disabledDatesPayDate = [];
  const disabledDatesPayPeriod = [];
  if (payFrequency === '4') {
    for (let year = 2023; year <= 2023; year++) {
      for (let month = 1; month <= 12; month++) {
        const lastDayOfMonth = new Date(year, month, 0).getDate();

        // Disable all dates except for 1st
        for (let day = 1; day <= lastDayOfMonth; day++) {
          if (day !== 1) {
            const date = `${year}-${month.toString().padStart(2, '0')}-${day
              .toString()
              .padStart(2, '0')}`;
            disabledDatesPayDate.push({ startDate: date, endDate: date });
          }
        }
        for (let day = 1; day <= lastDayOfMonth; day++) {
          if (day !== 1) {
            const date = `${year}-${month.toString().padStart(2, '0')}-${day
              .toString()
              .padStart(2, '0')}`;
            disabledDatesPayPeriod.push({ startDate: date, endDate: date });
          }
        }
      }
    }
  }
  return { disabledDatesPayDate, disabledDatesPayPeriod };
};
export const generateEmployeeSpoilerData = (data: EmployeeSpoilerData) => {
  if (isArrayHasData(data?.payments)) {
    const schema = data?.payments?.map(item => {
      return {
        employeeName:
          item.employee.firstName + ' ' + item.employee.middleName + ' ' + item.employee.lastName,
        employeeTaxes: item.employeeTaxes,
        otherDeductions: {
          benefits: (Array.isArray(item.benefits)
            ? item.benefits
            : Object.values(item.benefits)
          ).map(obj => {
            return {
              type: obj.type,
              name: obj.name,
              amount: obj.amount,
              employer_contribution_amount: obj.employer_contribution_amount,
            };
          }),
          garnishments: item.garnishments,
          postTaxDeductions: item.postTaxDeductions,
          oneTimeDeductions: item.oneTimeDeductions,
        },
        totalEmployeeTaxes: item.totalEmployeeTaxes,
        customHourlyRates: item.customHourlyRates,
      };
    });
    return schema;
  }
};

export const generateCompanySpolireDataPayrollDetails = (data: Item[]) => {
  const schema = data?.map(item =>
    item.employeeTaxes.map(tax => ({
      title: tax.name,
      value: tax.amount,
    })),
  );

  const descriptionCompanySpoiler = [];

  if (schema?.length > 0) {
    descriptionCompanySpoiler.push({
      name: 'Employer taxes',
      taxes: schema,
    });
  }

  const grossPayAndContributions = [];

  if (data?.grossPay !== 0) {
    grossPayAndContributions.push({
      title: 'Gross pay',
      value: data?.grossPay,
    });
  }
  if (data?.totalEmployerContributions !== 0) {
    grossPayAndContributions.push({
      title: 'Employer contribution',
      value: data?.totalEmployerContributions || 0,
    });
  }

  if (data?.totalReimbursements !== 0) {
    grossPayAndContributions.push({
      title: 'Reimbursement',
      value: data?.totalReimbursements || 0,
    });
  }

  if (grossPayAndContributions.length > 0) {
    descriptionCompanySpoiler.push({
      name: 'Gross pay and other contributions',
      taxes: grossPayAndContributions,
    });
  }

  return descriptionCompanySpoiler;
};

export const generateCompanySpolireData = (data: EmployeeSpoilerData) => {
  const schema = Object.values(data?.employerTaxes).map(
    (tax: { name: string; amount: number }) => ({
      title: tax?.name,
      value: tax?.amount,
    }),
  );

  const descriptionCompanySpoiler = [];

  if (schema.length > 0) {
    descriptionCompanySpoiler.push({
      name: 'Employer taxes',
      taxes: schema,
    });
  }

  const grossPayAndContributions = [];
  if (data?.grossPay !== 0) {
    grossPayAndContributions.push({
      title: 'Gross pay',
      value: data?.grossPay,
    });
  }

  if (data?.totalEmployerContributions !== 0) {
    grossPayAndContributions.push({
      title: 'Employer contribution',
      value: data?.totalEmployerContributions,
    });
  }

  if (data?.totalReimbursements !== 0) {
    grossPayAndContributions.push({
      title: 'Reimbursement',
      value: data?.totalReimbursements,
    });
  }

  if (grossPayAndContributions.length > 0) {
    descriptionCompanySpoiler.push({
      name: 'Gross pay and other contributions',
      taxes: grossPayAndContributions,
    });
  }

  return descriptionCompanySpoiler;
};

export const payFrequencyByValue = (num: number): keyof typeof tsMap | undefined => {
  const tsMap: Record<number, keyof typeof tsMap> = {
    1: 'Weekly',
    2: 'Bi-weekly',
    3: 'Semi-monthly',
    4: 'Monthly',
    5: 'Quarterly',
    6: 'Annually',
  };

  return tsMap[num];
};

export const returnHoursByPayFrequency = (payFrequency: number) => {
  const tsMap: Record<number, keyof typeof tsMap> = {
    1: 40,
    2: 80,
    3: 86.6666666667,
    4: 173.333333333,
    5: 520,
    6: 2080,
  };

  return tsMap[payFrequency];
};

export const keyUp = () => {
  return null;
};

export const getCookieData = (name: string) => {
  let pairs = document.cookie.split('; '),
    count = pairs.length,
    parts;
  while (count--) {
    parts = pairs[count].split('=');
    if (parts[0] === name) return parts[1];
  }
  return false;
};

export const findObjectByIdPayroll = (index: number, data: RunnablePayrolls) => {
  if (index >= 0 && index < data.length) {
    return data[index].isOverdue;
  } else {
    return false;
  }
};

export function useLocationEffect() {
  const location = useLocation();
  const [previousPathname, setPreviousPathname] = useState<string | null>(null);
  const uuid = useSelector((state: RootState) => state?.auth?.user?.account?.uuid);

  useEffect(() => {
    setPreviousPathname(location.pathname);
    if (location.pathname !== null && previousPathname !== null) {
      TagManager.dataLayer({
        dataLayer: {
          action: null,
          field: null,
          modal_response: null,
          step_name: null,
          step_num: null,
          prev_step_name: null,
          prev_step_num: null,
          route: null,
          prev_route: null,
          tab_name: null,
          tab_num: null,
          prev_tab_name: null,
          prev_tab_num: null,
        },
      });
      TagManager.dataLayer({
        dataLayer: {
          event: 'routeChange',
          newRoute: extractEventName(location.pathname),
          previousRoute: extractEventName(previousPathname),
          userId: uuid,
        },
      });
    }
  }, [location]);
}

export const extractEventName = (pathname: string) => {
  let eventName = pathname.replace(/\d+/g, '{id}');
  eventName = eventName.replace(/:/g, '');
  return eventName;
};

export const usePlatform = (): 'ios' | 'android' | 'web' => {
  const userAgent = window.navigator.userAgent;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent);
  const isAndroid = /Android/.test(userAgent);

  if (isIOS) {
    return 'ios';
  } else if (isAndroid) {
    return 'android';
  } else {
    return 'web';
  }
};

export const convertStepNameNewMember = (
  step: number,
  selectedContractor: boolean,
  isPaidThisYear: boolean,
) => {
  switch (step) {
    case 1:
      return 'AddNewMemberPage';
    case 2:
      return selectedContractor ? 'ContractorDetailsForm' : 'EmployeeDetailsForm';
    case 3:
      return selectedContractor ? 'ContractorHiringInformationForm' : 'EmployeeInformationForm';
    case 4:
      return selectedContractor
        ? 'ContractorCompensationContractorForm'
        : 'EmployeeCompensationForm';
    case 5:
      return selectedContractor ? 'CongratulationsNewContractor' : 'EmployeeTaxInformationForm';
    case 6:
      return selectedContractor ? 'CongratulationsNewContractor' : 'BenefitsAndGarnishments';
    case 7:
      return isPaidThisYear ? 'EmployeeYtdTaxDeductionsForm' : 'CongratulationsNewContractor';
    case 8:
      return 'CongratulationsNewContractor';
    case null:
      return null;
  }
};

export const onConfirmRefresh = function (event: {
  preventDefault: () => void;
  returnValue: string;
}) {
  event.preventDefault();
  return (event.returnValue = 'Are you sure you want to leave??');
};

export function hideCardNumber(cardNumber: string) {
  const visibleDigits = 4;
  const masked = '*'.repeat(cardNumber.length - visibleDigits) + cardNumber.slice(-visibleDigits);
  const formatted = masked.match(/.{1,4}/g)?.join(' '); // Используем опциональную цепочку
  return formatted || '';
}

export const isArrayHasData = <T = Array<any>>(arr: T) => Array.isArray(arr) && !!arr.length;

export const isObjHasData = (obj?: object | Record<string, any>) =>
  Boolean(obj) && typeof obj === 'object' && !!Object.keys(obj).length;

export const capitalizeFirstChar = (str: string) => {
  if (str && typeof str === 'string') return str.charAt(0).toUpperCase() + str.slice(1);
  return false;
};
export const isNotNullOrUndefined = (value: unknown) => value !== null && value !== undefined;

export function convertUsStateAbbrAndName(input: string): string | null {
  const toAbbr = input.length !== 2;

  const states = [
    ['Alabama', 'AL'],
    ['Alaska', 'AK'],
    ['American Samoa', 'AS'],
    ['Arizona', 'AZ'],
    ['Arkansas', 'AR'],
    ['Armed Forces Americas', 'AA'],
    ['Armed Forces Europe', 'AE'],
    ['Armed Forces Pacific', 'AP'],
    ['California', 'CA'],
    ['Colorado', 'CO'],
    ['Connecticut', 'CT'],
    ['Delaware', 'DE'],
    ['District Of Columbia', 'DC'],
    ['Florida', 'FL'],
    ['Georgia', 'GA'],
    ['Guam', 'GU'],
    ['Hawaii', 'HI'],
    ['Idaho', 'ID'],
    ['Illinois', 'IL'],
    ['Indiana', 'IN'],
    ['Iowa', 'IA'],
    ['Kansas', 'KS'],
    ['Kentucky', 'KY'],
    ['Louisiana', 'LA'],
    ['Maine', 'ME'],
    ['Marshall Islands', 'MH'],
    ['Maryland', 'MD'],
    ['Massachusetts', 'MA'],
    ['Michigan', 'MI'],
    ['Minnesota', 'MN'],
    ['Mississippi', 'MS'],
    ['Missouri', 'MO'],
    ['Montana', 'MT'],
    ['Nebraska', 'NE'],
    ['Nevada', 'NV'],
    ['New Hampshire', 'NH'],
    ['New Jersey', 'NJ'],
    ['New Mexico', 'NM'],
    ['New York', 'NY'],
    ['North Carolina', 'NC'],
    ['North Dakota', 'ND'],
    ['Northern Mariana Islands', 'NP'],
    ['Ohio', 'OH'],
    ['Oklahoma', 'OK'],
    ['Oregon', 'OR'],
    ['Pennsylvania', 'PA'],
    ['Puerto Rico', 'PR'],
    ['Rhode Island', 'RI'],
    ['South Carolina', 'SC'],
    ['South Dakota', 'SD'],
    ['Tennessee', 'TN'],
    ['Texas', 'TX'],
    ['US Virgin Islands', 'VI'],
    ['Utah', 'UT'],
    ['Vermont', 'VT'],
    ['Virginia', 'VA'],
    ['Washington', 'WA'],
    ['West Virginia', 'WV'],
    ['Wisconsin', 'WI'],
    ['Wyoming', 'WY'],
  ];

  // So happy that Canada and the US have distinct abbreviations
  const provinces = [
    ['Alberta', 'AB'],
    ['British Columbia', 'BC'],
    ['Manitoba', 'MB'],
    ['New Brunswick', 'NB'],
    ['Newfoundland', 'NF'],
    ['Northwest Territory', 'NT'],
    ['Nova Scotia', 'NS'],
    ['Nunavut', 'NU'],
    ['Ontario', 'ON'],
    ['Prince Edward Island', 'PE'],
    ['Quebec', 'QC'],
    ['Saskatchewan', 'SK'],
    ['Yukon', 'YT'],
  ];

  const regions = states.concat(provinces);

  let i; // Reusable loop variable

  if (toAbbr) {
    input = input.replace(/\w\S*/g, function (txt: string) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    for (i = 0; i < regions.length; i++) {
      if (regions[i][0] === input) {
        return regions[i][1];
      }
    }
  } else {
    input = input.toUpperCase();
    for (i = 0; i < regions.length; i++) {
      if (regions[i][1] === input) {
        return regions[i][0];
      }
    }
  }

  return null;
}

// Check if Account is newer to ask Persona questions
export const checkAccountForPersona = (createdAt: string) => {
  const inputDate = new Date(createdAt);
  const comparisonDate = new Date('2023-12-20T00:00:00+00:00');

  return inputDate >= comparisonDate;
};

// Check if Account is newer to ask Onboarding Customization question
// Onboarding Customization is added on 2024-03-27.
// So, skip Customization questions for account that are older than this date.
export const checkAccountForOnboardingCustomization = (createdAtString: string) => {
  const createdAt = new Date(createdAtString);

  if (isNaN(createdAt.getTime())) {
    return false; // Return false if createdAt date is not valid.
  }

  const comparisonDate = new Date('2024-03-27T00:00:00+00:00');
  return createdAt >= comparisonDate;
};

export const checkAccountForFreeTrialCustomization = (createdAtString: string) => {
  const createdAt = new Date(createdAtString);

  if (isNaN(createdAt.getTime())) {
    return false; // Return false if createdAt date is not valid.
  }

  const comparisonDate = new Date('2024-06-28T00:00:00+00:00');
  return createdAt >= comparisonDate;
};

/* Handle tax data based on the hiding algorithm
 *
 * Employer Taxes will have a hidingAlgorithm as a property, both on the top level of each Tax, but only on each item of the jurisdictionData. If the hidingAlgorithm property doesn't exist, assume its value is "default".
 *
 * Based on the value of the hidingAlgorithm`, we will do the value:
 *
 *   1. If the value is default:
 *       a.If on the jurisdictionData:
 *           1. Hide it from the stepper ONLY if its type is numeric (Percentage, Money, Integer), and it has a defaultValue that’s above “0”
 *           2. Still show it in the summary
 *           3. Still show it if its type is non-numeric (current implementation doesn’t do that)
 *
 *       b. If on the taxItem:
 *           1. Hide it from the stepper if it’s rate is above 0%, and all its jurisdictionData are hidden too (based on each one’s hidingAlgorithm)
 *           2. Still show it in the summary
 *
 *   2. If the value is hideIfExempt:
 *       a. This only works on the taxItem:
 *           1. Hide it from the stepper and the summary if the taxItem has isExempt: true
 *           2. Otherwise show it in both
 *       b. If we find this on the jurisdictionData, do a console.error mentioning which tax & field has this issue
 *
 *   3. If the value is hideIfDefaultValue
 *       a. On the jurisdictionData:
 *           1. Hide on the stepper and the summary if the defaultValue is not null (so even if it’s 0)
 *       b. On the taxItem:
 *           1. Hide it from stepper and the summary if the rate has a value (even if 0), and all the jurisdictionData fields are hidden too (based on each one’s hidingAlgorithm)
 *
 */

export const handleEmployerTaxData = ({
  taxItems,
  shouldShowAllFields,
}: {
  taxItems: TaxItem[];
  shouldShowAllFields: boolean;
}) => {
  const defaultHidingAlgorithm = 'default';
  const RATE_FIELD_NAME = 'RATE';

  if (!Array.isArray(taxItems) || !taxItems?.length) {
    return [];
  }

  const formattedTaxItems = taxItems.reduce((prevTaxItems: TaxItem[], taxItem) => {
    // Format fields other than the RATE field
    const formattedFields = taxItem.fields
      .filter(item => item.name !== RATE_FIELD_NAME)
      .filter(field => {
        const fieldHidingAlgorithm: HidingAlgorithmType = field.hidingAlgorithm
          ? field.hidingAlgorithm
          : defaultHidingAlgorithm;

        switch (fieldHidingAlgorithm) {
          case 'default': {
            if (shouldShowAllFields) {
              return true;
            } else {
              return (
                ['String', 'Choice', 'List'].includes(field.type) ||
                (['Money', 'Percentage', 'Integer'].includes(field.type) &&
                  !(field.defaultValue && Number(field.defaultValue) > 0))
              );
            }
          }
          case 'hideIfExempt': {
            console.error(
              "'hideIfExempt' hidingAlgorithm found in tax field",
              field,
              'in taxItem',
              taxItem,
            );
            return false;
          }
          case 'hideIfDefaultValue': {
            return field.defaultValue === null;
          }
        }
      });

    const itemHidingAlgorithm: HidingAlgorithmType = taxItem.hidingAlgorithm
      ? taxItem.hidingAlgorithm
      : defaultHidingAlgorithm;

    const rateField = taxItem.fields.find(item => item.name === RATE_FIELD_NAME);

    let shouldShowTaxItem = true;

    switch (itemHidingAlgorithm) {
      case 'default': {
        shouldShowTaxItem =
          shouldShowAllFields || !(Number(rateField?.defaultValue) > 0 && !formattedFields.length);
        break;
      }
      case 'hideIfExempt': {
        shouldShowTaxItem = !taxItem.isExempt;
        break;
      }
      case 'hideIfDefaultValue': {
        shouldShowTaxItem = rateField?.defaultValue === null || !!formattedFields.length;
        break;
      }
    }

    if (shouldShowTaxItem) {
      return [
        ...prevTaxItems,
        { ...taxItem, fields: rateField ? [rateField, ...formattedFields] : formattedFields },
      ];
    }
    return prevTaxItems;
  }, []);

  return formattedTaxItems;
};

export const addressesAreEqual = (address1: Address, address2: Address) => {
  if (!address2) {
    return false;
  }
  return (
    address1.streetAddress === address2.streetAddress &&
    address1.city === address2.city &&
    address1.state === address2.state &&
    address1.zipCode === address2.zipCode &&
    address1.aptSteNumber === address2.aptSteNumber
  );
};

// Check if Account is newer to show account-setup layout
// Account-setup is added on 2024-05-30.
// So, skip Account-setup for account that are older than this date.
export const checkAccountForAccountSetup = ({ account }: { account: Account }) => {
  let shouldShowAccountSetup = true;
  let isCompanyStepCompleted = false;
  let isScheduleStepCompleted = false;
  let isTaxesStepCompleted = false;
  let isEmployeesStepCompleted = false;
  let isContractorStepCompleted = false;
  let isAccountSetupCompleted = false;
  let isFinishOnboardingStepCompleted = false;
  let onBoardingStructure = ONBOARDING_STRUCTURE.both;
  // For new Add team member flow
  let isTeamMemberStepCompleted = false;

  const { company, createdAt, metadata } = account ?? {};

  const companyData = company?.[0] ?? {};

  const {
    completedContractor,
    completedEmployee,
    payrollSchedule,
    missingTaxes,
    numberOfContractors,
    numberOfEmployees,
    address,
    businessRole,
    businessType,
    workLocations,
    ein,
    ssn,
  } = companyData;

  const createdAtDate = new Date(createdAt);

  const comparisonDate = new Date('2024-05-30T00:00:00+00:00');
  const isExistingAccount = createdAtDate <= comparisonDate;

  if (account) {
    if (isExistingAccount) {
      shouldShowAccountSetup = false;
    } else {
      if (Number(numberOfContractors) >= 0 && Number(numberOfEmployees) === 0) {
        onBoardingStructure = ONBOARDING_STRUCTURE.contractor;
      } else if (Number(numberOfEmployees) >= 0 && Number(numberOfContractors) === 0) {
        onBoardingStructure = ONBOARDING_STRUCTURE.employee;
      }

      isCompanyStepCompleted = !!(businessRole && businessType && address && (ein || ssn));

      const existingMetaData = account.metadata;

      const formattedMetaData =
        existingMetaData &&
        !Array.isArray(existingMetaData) &&
        Object.entries(existingMetaData).length
          ? existingMetaData
          : {};

      const {
        passedAccountSetupEmployeeStep,
        passedAccountSetupContractorStep,
        passedAccountSetupFinishOnboardingStep,
        passedAccountSetupTeamMemberStep,
        passedAccountSetupEarningTypesStep,
      } = formattedMetaData;

      /**
       * Earning System deployment date; 2024-08-12
       * Earlier we only have the payroll schedule,
       * After the Earning system, Payroll setting contains 2 tabs: Payroll schedule and Pay Types
       * As Earning system doesn't have any data to check it is saved or not, we are doing based on the date and the metadata.
       **/

      const earningSystemDeploymentDate = new Date('2024-08-12T00:00:00+00:00');

      const isAccountBeforeEarningSystem = createdAtDate <= earningSystemDeploymentDate;

      if (isAccountBeforeEarningSystem) {
        isScheduleStepCompleted = payrollSchedule;
      } else {
        isScheduleStepCompleted = payrollSchedule && !!passedAccountSetupEarningTypesStep;
      }

      isTaxesStepCompleted = !missingTaxes;

      isEmployeesStepCompleted = !!passedAccountSetupEmployeeStep;
      isContractorStepCompleted = !!passedAccountSetupContractorStep;
      isTeamMemberStepCompleted =
        !!passedAccountSetupTeamMemberStep ||
        !!(passedAccountSetupEmployeeStep && passedAccountSetupContractorStep);

      isFinishOnboardingStepCompleted = !!passedAccountSetupFinishOnboardingStep;

      const requiredSteps = {
        [ONBOARDING_STRUCTURE.both]: [
          isScheduleStepCompleted,
          isTaxesStepCompleted,
          isEmployeesStepCompleted,
          isContractorStepCompleted,
        ],
        [ONBOARDING_STRUCTURE.employee]: [
          isScheduleStepCompleted,
          isTaxesStepCompleted,
          isEmployeesStepCompleted,
        ],
        [ONBOARDING_STRUCTURE.contractor]: [isContractorStepCompleted],
      };

      const stepsToCheck =
        requiredSteps[onBoardingStructure] || requiredSteps[ONBOARDING_STRUCTURE.both];

      isAccountSetupCompleted = isFinishOnboardingStepCompleted;
    }
  }

  return {
    shouldShowAccountSetup,
    isCompanyStepCompleted,
    isScheduleStepCompleted,
    isEmployeesStepCompleted,
    isContractorStepCompleted,
    isAccountSetupCompleted,
    isTaxesStepCompleted,
    isFinishOnboardingStepCompleted,
    onBoardingStructure,
    isTeamMemberStepCompleted,
  };
};
