import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useSaveOnboardingCustomization from '@/modules/dashboard/apis/useSaveOnboardingCustomization';
import { Dispatch, RootState } from '@/redux/store';
import * as Routes from '@/routes/routes';
import { customizationOptions } from '@/utils/constants';
import {
  checkAccountForAccountSetup,
  checkAccountForOnboardingCustomization,
} from '@/utils/helpers';

const useAccountSetup = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const [shouldShowCustomizationModal, setShouldShowCustomizationModal] = useState(false);
  const [isFetchingAccountDetails, setIsFetchingAccountDetails] = useState(false);

  const accountInfo = useSelector((state: RootState) => state.auth.onboardingInfo?.account);
  const accountId = accountInfo?.id;
  const firstName = accountInfo?.firstName;

  const payrollManagement = accountInfo?.payrollManagement;
  const createdAtDate = accountInfo?.createdAt;

  const onSuccessFullySaveCustomization = async () => {
    try {
      setIsFetchingAccountDetails(true);
      await dispatch.auth.getAccountInformation();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingAccountDetails(false);
      setShouldShowCustomizationModal(false);
    }
  };

  const { mutate: saveOnboardingCustomization, isPending: isSavingOnboardingCustomization } =
    useSaveOnboardingCustomization({
      accountId,
      mutationParams: {
        onSuccess: onSuccessFullySaveCustomization,
      },
    });

  const isSavingCustomization = isSavingOnboardingCustomization || isFetchingAccountDetails;

  useEffect(() => {
    setShouldShowCustomizationModal(
      !payrollManagement && checkAccountForOnboardingCustomization(createdAtDate),
    );
  }, [payrollManagement, createdAtDate]);

  const customizationModalCancelHandler = () => {
    setShouldShowCustomizationModal(false);
  };

  const onSaveCustomizationHandler = (value: string | number | boolean | null) => {
    saveOnboardingCustomization({ payrollManagement: value });
  };

  const { shouldShowAccountSetup, isCompanyStepCompleted } = checkAccountForAccountSetup({
    account: accountInfo,
  });

  const { metadata } = accountInfo;

  const isStartedFreeTrail =
    !Array.isArray(metadata) && metadata['startedFreeTrial'] ? metadata['startedFreeTrial'] : false;

  useEffect(() => {
    if (!isStartedFreeTrail) {
      navigate(Routes.SIGNUP_STEPPER);
    } else if (
      accountInfo &&
      (!shouldShowAccountSetup || (shouldShowAccountSetup && isCompanyStepCompleted))
    ) {
      navigate(Routes.DASHBOARD);
    }
  }, [accountInfo, shouldShowAccountSetup, isCompanyStepCompleted, navigate]);

  return {
    shouldShowCustomizationModal,
    customizationModalCancelHandler,
    onSaveCustomizationHandler,
    customizationOptions,
    isSavingCustomization,
    firstName,
  };
};

export default useAccountSetup;
