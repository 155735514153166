import {
  PlansModal,
  SubscriptionCard,
  SubscriptionFreeContainer,
  UpgradePlanModal,
} from '@/components';
import Button from '@/components/Button/Button';
import DialogComponent from '@/components/Dialog/DialogComponent';

import AddNewPaymentMethodDrawer from '../AddNewPaymentMethodDrawer/AddNewPaymentMethodDrawer';
import BillingHistory from '../BillingHistory/BillingHistory';
import { CancelSubscriptionReworked } from '../CancelSubscriptionModal/CancelSubscriptionReworked';
import PaymentMethodsCards from '../PaymentMethodsCards/PaymentMethodsCards';
import RenewPlanModal from '../RenewPlanModal/RenewPlanModal';
import useBillingCenter from './useBillingCenter';

const BillingCenter = ({ onGotoDashboard }: any) => {
  const {
    subscriptionInformation,
    trialDays,
    sortedPaymentMethods,
    availableProducts,
    calculateTrialDays,
    handleRenewModal,
    toggleDeletePaymentModal,
    handlePaymentMethodModal,
    isAppModeDemo,
    invoiceHistory,
    formattedDate,
    isOpenPaymentMethodModal,
    handleOpenCancelModal,
    isPlanModalOpen,
    handleViewPlansModal,
    plans,
    isOpenedCancelModal,
    isRenewModalOpen,
    isUpgradeModalOpen,
    setIsUpgradeModalOpen,
    viewPlanClickHandler,
  } = useBillingCenter();

  return (
    <div className="max-w-[670px] px-5 pb-5 md:px-8 md:pb-8">
      <div className="flex items-center justify-between">
        <h1 className="font-F37Bolton-Medium text-xl">PayStubs billing</h1>
        {subscriptionInformation?.activeSubscriptionAgreement?.scheduledToBeCancelled ? (
          <Button
            variant="primary"
            width="w-auto"
            parentClasses="px-6 py-3"
            onPress={handleRenewModal}
          >
            Renew subscription
          </Button>
        ) : (
          <Button
            variant="text"
            parentClasses=" text-emerald-500 h-fit"
            onPress={() => viewPlanClickHandler()}
          >
            View all plans
          </Button>
        )}
      </div>
      {subscriptionInformation?.activeSubscriptionAgreement === null ||
      subscriptionInformation?.activeSubscriptionAgreement?.status === 'canceled' ? (
        <SubscriptionFreeContainer
          description="You will not be charged, and can cancel at any time"
          buttonText={`Start ${trialDays}-day free trial`}
          planDescription={true}
        />
      ) : (
        <div className="w-full ">
          <div className={` flex min-h-[80px] w-full flex-col gap-4 border-gray-100 `}>
            <div className="space-y-2">
              <SubscriptionCard
                label={
                  availableProducts?.find(
                    card => card.name === subscriptionInformation?.productName,
                  )?.name || 'Starter'
                }
                description={
                  availableProducts?.find(
                    card => card.name === subscriptionInformation?.productName,
                  )?.description
                }
                canceledDate={subscriptionInformation?.activeSubscriptionAgreement?.canceledDate}
                type={subscriptionInformation?.productName}
                status={calculateTrialDays()}
                daysLeft={
                  subscriptionInformation?.activeSubscriptionAgreement?.cancellationRemaining
                }
              />
            </div>
            <div className=" flex h-[60px] w-full items-center justify-between rounded-xl border  border-gray-100 p-4">
              <span className="text-gray-400">Next invoice</span>
              <span className="flex flex-col ">
                {subscriptionInformation?.activeSubscriptionAgreement.scheduledToBeCancelled ||
                subscriptionInformation?.activeSubscriptionAgreement.status === 'canceled'
                  ? 'Cancelled'
                  : formattedDate}
              </span>
            </div>

            <div className="flex flex-col items-end">
              <div>
                {!subscriptionInformation?.activeSubscriptionAgreement.scheduledToBeCancelled &&
                  subscriptionInformation?.activeSubscriptionAgreement.status !== 'canceled' && (
                    <div className="mt-2 flex items-end justify-end">
                      <Button
                        variant="text"
                        children="Cancel subscription"
                        width="w-auto"
                        isDisabled={isAppModeDemo}
                        parentClasses=" text-red-500 h-fit"
                        onPress={handleOpenCancelModal}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="mt-[40px] flex justify-between">
            <span className="font-F37Bolton-Medium text-xl text-gray-900">Payments Methods</span>
            <Button variant="text" className="text-emerald-500" onPress={handlePaymentMethodModal}>
              +Add new
            </Button>
          </div>

          {/* use paymentMethods for mapping */}

          <PaymentMethodsCards
            paymentMethods={sortedPaymentMethods || []}
            handleDeleteMethod={toggleDeletePaymentModal}
          />
        </div>
      )}
      <BillingHistory invoices={invoiceHistory} />
      {subscriptionInformation?.activeSubscriptionAgreement?.id && isOpenedCancelModal && (
        <DialogComponent
          open={isOpenedCancelModal}
          onCancel={handleOpenCancelModal}
          children={<CancelSubscriptionReworked onClose={handleOpenCancelModal} />}
          width="w-[100vw] lg:w-[610px]"
          height="h-[100vh] lg:max-h-[800px]"
        />
      )}
      {isPlanModalOpen && (
        <PlansModal plans={plans} isOpen={isPlanModalOpen} onClose={handleViewPlansModal} />
      )}
      {isOpenPaymentMethodModal && (
        <AddNewPaymentMethodDrawer
          isOpen={isOpenPaymentMethodModal}
          onClose={handlePaymentMethodModal}
        />
      )}
      {subscriptionInformation?.activeSubscriptionAgreement === null ||
      subscriptionInformation?.activeSubscriptionAgreement?.status === 'canceled' ? null : (
        <RenewPlanModal
          isOpen={isRenewModalOpen}
          currentPlan={subscriptionInformation?.productName}
          maxSeats={subscriptionInformation?.maxSeats}
          toggleModal={handleRenewModal}
          showAllSubscriptions={false}
          onGotoDashboard={onGotoDashboard}
        />
      )}
      {subscriptionInformation?.activeSubscriptionAgreement === null ||
      subscriptionInformation?.activeSubscriptionAgreement?.status === 'canceled' ? null : (
        <UpgradePlanModal
          isOpen={isUpgradeModalOpen}
          currentPlan={subscriptionInformation?.productName}
          maxSeats={subscriptionInformation?.maxSeats}
          toggleModal={function (isOpen: boolean): void {
            setIsUpgradeModalOpen(isOpen);
          }}
          showAllSubscriptions={false}
        />
      )}
    </div>
  );
};

export default BillingCenter;
