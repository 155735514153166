import { PopupButton } from 'react-calendly';
import { twMerge } from 'tailwind-merge';

import Button from '@/components/Button/Button';

import { AccountSetupSupportType } from './type';

const AccountSetupSupport = ({
  onBookCall,
  isShowChatWithMe,
  bookCallClasses,
}: AccountSetupSupportType) => {
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex flex-wrap gap-[14px]">
          <div>
            <img
              src="https://uploads-ssl.webflow.com/63d39d61533099307dbc0bf8/6639dbfeb3883789ae817a53_JaysonHeadshot.png"
              className="size-12"
              alt="jayson"
            />
          </div>
          <div className="">
            <p className="font-F37Bolton-Medium text-lg">Hi, I&apos;m Jayson!</p>
            <p className="text-sm">I&apos;m here if you need help.</p>
          </div>
        </div>
        <div className="mt-4 flex gap-2">
          {isShowChatWithMe && (
            <Button
              variant="primary"
              parentClasses="hidden sm:flex w-full"
              onPress={() => {
                window.fcWidget.open();
              }}
            >
              Chat with me
            </Button>
          )}

          {/* <a
            href={'tel:+18887781009'}
            className="flex h-[48px] w-full items-center justify-center rounded-[8px] border border-gray-400 text-gray-400 sm:hidden"
          >
            {'Call now'}
          </a> */}

          {onBookCall ? (
            <Button variant="primary" onPress={onBookCall}>
              Book a call
            </Button>
          ) : (
            <PopupButton
              url="https://calendly.com/d/3xy-ywd-knc/chat-with-paystubs-com-team"
              rootElement={document.getElementById('root')}
              text="Book a call"
              className={twMerge(
                'h-[48px] w-full rounded-[8px] bg-emerald-500 text-white',
                bookCallClasses,
              )}
            />
          )}
        </div>
        <div className="mt-3 flex justify-center">
          <p className="hidden sm:flex">
            <span>Call now:</span>
            <span className="ml-1 text-darkblue-300"> (888) 870-1009</span>
          </p>
          <Button
            variant="text"
            className="cursor-pointer break-words text-emerald-500 underline underline-offset-2 sm:hidden"
            onPress={() => {
              window.fcWidget.open();
            }}
          >
            Chat with me instead
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountSetupSupport;
