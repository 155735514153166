import DialogComponent from '@/components/Dialog/DialogComponent';
import PricingPlan from '@/components/PricingPlan/PricingPlan';
import { PlansModalProps, PlansProps } from './type';

import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export const PlansModal = ({ isOpen, onClose, plans }: PlansModalProps) => {
  return (
    <DialogComponent
      open={isOpen}
      onCancel={onClose}
      children={
        <>
          <div className="sticky top-0  bg-white">
            <div className="flex w-full items-center justify-between px-5">
              <Button variant="text" onPress={onClose}>
                <ArrowLeftIcon className="mr-1 w-3.5" />
                <span>Back</span>
              </Button>
              <Button variant="icon" icon={CloseIcon} onPress={onClose} parentClasses="border-2" />
            </div>
            <hr />
          </div>

          <div className="my-[32px] flex w-full flex-col items-center justify-center px-[30px] md:my-[40px] md:px-[70px]">
            <div className="w-full px-[20px] text-center lg:max-w-2xl">
              <span className="block font-F37Bolton-Medium text-[24px] lg:text-[28px]">
                Here are our plans!
              </span>
              <span className="mx-auto block  text-base text-gray-500">
                You can choose the plan that best suits your needs at the end of your free trial.
              </span>
            </div>
            <div className="mt-[32px] grid w-full max-w-[700px] grid-cols-1 items-center justify-items-center gap-4 sm:grid-cols-2">
              {plans?.map(({ plan, description, price }: PlansProps) => (
                <div className="w-full cursor-pointer">
                  <PricingPlan
                    parentClasses=" w-full lg:max-w-[308px] "
                    isTrialButtonVisible={false}
                    description={description}
                    plan={plan}
                    price={price}
                  />
                </div>
              ))}
            </div>
            <div className="mt-8 flex flex-col items-center justify-center">
              <span className="block font-F37Bolton-Medium text-[18px]">
                Can’t find a plan that suits you?
              </span>

              <span className="font-F37Bolton-Medium text-base text-gray-300">
                Contact support at end of the free trial for a custom plan.
              </span>
            </div>
          </div>
        </>
      }
      width={`w-screen sm:w-[90vw] lg:w-[780px]`}
      height={`h-screen md:h-[85vh] xl:max-h-[800px]`}
      modalClasses="sm:rounded-lg"
    />
  );
};
