import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetSubscriptionPlans } from '@/api/services/apis/subscriptionPlans/useGetSubscriptionPlans';
import { RootState } from '@/redux/store';
import { AVAILABLE_SUBSCRIPTIONS, TRIAL_DAYS } from '@/utils/constants';
import { extractEventName } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

import { UseSubscriptionCardType } from './types';

const useSubscriptionCard = ({ discount, type, daysLeft }: UseSubscriptionCardType) => {
  const subscriptionInfo = useSelector((state: RootState) => state.auth?.subscriptionInfo);
  const activeSubscriptionAgreement = subscriptionInfo?.activeSubscriptionAgreement || {};

  const trialDays =
    useSelector((state: RootState) => state.auth?.callbackOnboardingInfo?.trialDays) ?? TRIAL_DAYS;

  const { data: { availableProducts } = {} } = useGetSubscriptionPlans({});

  const formattedAvailableProducts = useMemo(
    () => (Array.isArray(availableProducts) ? availableProducts : []),
    [availableProducts],
  );

  const planData = Array.isArray(formattedAvailableProducts)
    ? formattedAvailableProducts.find(item => item.name === type)
    : null;

  const planPrice = planData?.availablePrices.find(
    item => item.pricingStrategy === 'monthly',
  )?.amount;

  const handleCardClick = (type: string) => {
    trackEvent('buttonClick', {
      name: 'SubscriptionCard',
      currentPage: extractEventName(window.location.pathname),
      type: type,
    });
  };

  const calcPriceWithDiscount = () => {
    if (discount && type && planPrice) {
      if (discount.type === 'fixed') {
        return Math.max(0, planPrice - discount.discount);
      } else if (type && discount.type === 'percentage') {
        if (planPrice) {
          const discountAmount = planPrice * (discount.discount / 100);
          return Math.max(0, planPrice - discountAmount);
        }
      }
    }
  };

  const calcDaysUntilCancellation = () => {
    if (daysLeft) {
      return daysLeft;
    }
  };

  const renderPrice = useCallback(() => {
    const advancedPlan = formattedAvailableProducts.find(
      item => item.name === AVAILABLE_SUBSCRIPTIONS.advanced,
    );
    const advancedPlanPrice = advancedPlan
      ? advancedPlan.availablePrices.find(item => item.pricingStrategy === 'monthly')?.amount
      : null;

    let priceDescription = `$${advancedPlanPrice || 0} / mo.`;

    if (type === 'contractor-base') {
      priceDescription = `$19.99 + $4 per contractor`;
    } else if (type && typeof planPrice === 'number') {
      priceDescription = `$${planPrice.toFixed(2)} / mo.`;
    }

    return <span className="font-F37Bolton-Medium ">{priceDescription}</span>;
  }, [type, formattedAvailableProducts, planPrice]);

  return {
    activeSubscriptionAgreement,
    trialDays,
    handleCardClick,
    calcDaysUntilCancellation,
    calcPriceWithDiscount,
    planPrice,
    renderPrice,
  };
};

export default useSubscriptionCard;
