import { useQueryClient } from '@tanstack/react-query';

import { useQueryHandlersProps } from './types';

const useQueryHandlers = ({ mutationParams, invalidateKeys }: useQueryHandlersProps) => {
  const queryClient = useQueryClient();
  const onSuccess = (...onSuccessParams) => {
    if (mutationParams?.onSuccess) {
      mutationParams?.onSuccess(...onSuccessParams);
    }
    invalidateKeys.forEach(key => queryClient.invalidateQueries({ queryKey: key }));
  };

  return { onSuccess };
};

export default useQueryHandlers;
