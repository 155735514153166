import { DateTime } from 'luxon';
import { NumericFormat } from 'react-number-format';

import { TableCell } from '@/components';
import Button from '@/components/Button/Button';

import { BillingHistoryTableRowProps } from '../../type';

const BillingHistoryTableRow = ({
  billData,
  isLoading,
  handleAction,
}: BillingHistoryTableRowProps) => {
  const payDate = DateTime.fromISO(billData?.dueDate, {
    zone: 'utc',
  }).toFormat('MMM d, y');

  return (
    <>
      <TableCell className="px-4 py-3 ">
        <span className="capitalize">{billData?.plan}</span>
      </TableCell>
      <TableCell className="px-4 py-3 ">
        <span className="text-sm">{payDate}</span>
      </TableCell>
      <TableCell className="px-4 py-3 text-end">
        <NumericFormat
          value={billData?.amount}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          displayType="text"
          decimalScale={2}
          fixedDecimalScale
          renderText={value => <span className="text-sm">${value}</span>}
        />
      </TableCell>
      <TableCell className="border-r px-4 py-3 ">
        <Button
          variant="light"
          parentClasses="rounded-lg h-[35px] max-w-[160px] text-sm py-2 px-6 !text-black"
          isLoading={isLoading}
          onPress={handleAction}
        >
          {billData?.fsInvoiceUrl ? 'View receipt' : 'Download receipt'}
        </Button>
      </TableCell>
    </>
  );
};

export default BillingHistoryTableRow;
