import { DateTime } from 'luxon';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/redux/store';
import { FREE_3_MONTH_TRIAL_SUBSCRIPTION, TRIAL_DAYS } from '@/utils/constants';

const useBillingCenter = () => {
  const [isOpenedCancelModal, setIsOpenedCancelModal] = useState<boolean>(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState<boolean>(false);

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false);
  const [isRenewModalOpen, setIsRenewModalOpen] = useState<boolean>(false);
  const [selectedPaymentMethodId, setSelectedPaymentId] = useState<number>();
  const [isOpenDeletePaymentModal, setIsOpenDeletePaymentModal] = useState<boolean>(false);

  const [isOpenPaymentMethodModal, setIsOpenPaymentMethodModal] = useState<boolean>(false);
  const subscriptionInformation = useSelector((state: RootState) => state.auth.subscriptionInfo);

  const invoiceHistory = useSelector(
    (state: RootState) => state.auth.subscriptionInfo?.invoicesToDisplay,
  );

  const date = subscriptionInformation?.activeSubscriptionAgreement?.nextProcessingDate;

  const formattedDate = date && moment(date).isValid() ? moment(date).format('MMM DD, YYYY') : null;

  const trialDays =
    useSelector((state: RootState) => state?.auth?.callbackOnboardingInfo?.trialDays) ?? TRIAL_DAYS;

  const paymentMethods = useSelector(
    (state: RootState) => state.auth.subscriptionInfo?.paymentMethods,
  );

  const sortedPaymentMethods = paymentMethods?.sort(
    (a, b) => Number(b.isDefault) - Number(a.isDefault),
  );

  const { availableProducts } =
    useSelector((state: RootState) => state.auth?.subscriptionPlan) ?? [];

  const calculateTrialDays = () => {
    if (subscriptionInformation?.activeSubscriptionAgreement?.status === 'trialing') {
      const now = DateTime.now().setZone('UTC');
      const remainingDays = date ? Math.ceil(moment(date).diff(now, 'days')) : 0;

      if (remainingDays > 0) {
        return `${remainingDays} days left in your free trial`;
      } else {
        return '0 days left in your free trial';
      }
    } else {
      if (subscriptionInformation?.activeSubscriptionAgreement.scheduledToBeCancelled) {
        return 'scheduledToBeCancelled';
      } else {
        return subscriptionInformation?.activeSubscriptionAgreement.status;
      }
    }
  };

  const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

  const handleRenewModal = () => setIsRenewModalOpen(!isRenewModalOpen);
  const handleUpgradeModal = () => setIsUpgradeModalOpen(!isUpgradeModalOpen);
  const handlePaymentMethodModal = () => setIsOpenPaymentMethodModal(!isOpenPaymentMethodModal);
  const handleOpenCancelModal = () => setIsOpenedCancelModal(!isOpenedCancelModal);

  const toggleDeletePaymentModal = (id: number) => {
    setIsOpenDeletePaymentModal(true);
    setSelectedPaymentId(id);
  };
  const handleViewPlansModal = () => setIsPlanModalOpen(!isPlanModalOpen);

  const plans = [
    {
      plan: 'Starter',
      price: '$29.99',
      description: (
        <>
          Ideal for small businesses handling up to{' '}
          <span className="font-F37Bolton-Medium">2 employees.</span>
        </>
      ),
    },
    {
      plan: 'Pro',
      price: '$34.99',
      description: (
        <>
          Ideal for small businesses handling up to{' '}
          <span className="font-F37Bolton-Medium">5 employees.</span>
        </>
      ),
    },
    {
      plan: 'Advanced',
      price: '$69.99',
      description: (
        <>
          Ideal for small businesses handling up to{' '}
          <span className="font-F37Bolton-Medium">15 employees.</span>
        </>
      ),
    },
    {
      plan: 'Advanced Plus',
      price: '$99.99',
      description: (
        <>
          Ideal for small businesses handling up to{' '}
          <span className="font-F37Bolton-Medium">25 employees.</span>
        </>
      ),
    },
  ];

  const isFreeTrialActive =
    subscriptionInformation?.productName === FREE_3_MONTH_TRIAL_SUBSCRIPTION;

  const viewPlanClickHandler = () => {
    if (isFreeTrialActive) {
      setIsPlanModalOpen(true);
    } else {
      setIsUpgradeModalOpen(!isUpgradeModalOpen);
    }
  };

  return {
    subscriptionInformation,
    trialDays,
    sortedPaymentMethods,
    availableProducts,
    calculateTrialDays,
    handleRenewModal,
    handleUpgradeModal,
    toggleDeletePaymentModal,
    handlePaymentMethodModal,
    isAppModeDemo,
    invoiceHistory,
    formattedDate,
    handleOpenCancelModal,
    isOpenPaymentMethodModal,
    isPlanModalOpen,
    handleViewPlansModal,
    plans,
    isOpenedCancelModal,
    isRenewModalOpen,
    isUpgradeModalOpen,
    setIsUpgradeModalOpen,
    viewPlanClickHandler,
  };
};
export default useBillingCenter;
