import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as MasterCardIcon } from '@/assets/masterCardIcon.svg';
import { ReactComponent as PayPallLogoColor } from '@/assets/paypalColorLogo.svg';
import { ReactComponent as VisaCardLogo } from '@/assets/visaCardIcon.svg';
import { LoaderComponent } from '@/components';
import { Dispatch } from '@/redux/store';

import ActionPaymentMethod from './ActionPaymentMethod';

interface PaymentMethod {
  id: number;
  name: string;
  type: string;
  isDefault: boolean;
  cardInfo: {
    type: string;
    last4: number;
  };
}

interface PaymentMethodsCardProps {
  paymentMethods: PaymentMethod[];
  handleDeleteMethod: (id: number) => void;
}

const PaymentMethodsCards: React.FC<PaymentMethodsCardProps> = ({ paymentMethods }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<Dispatch>();

  const handleSetAsDefaultMethod = async (id: number) => {
    setIsLoading(true);
    await dispatch.auth.setDefaultPaymentMethod({ id });
    setIsLoading(false);
  };

  const handleDeletePaymentMethod = async (id: number) => {
    setIsLoading(true);
    await dispatch.auth.deletePaymentMethod({ id });
    setIsLoading(false);
  };

  return (
    <div className="mt-3 space-y-4">
      <>
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            {paymentMethods.map(method => (
              <PaymentMethodCard
                key={method.id}
                method={method}
                handleDeleteMethod={() => handleDeletePaymentMethod(method.id)}
                handleSetDefault={() => handleSetAsDefaultMethod(method.id)}
              />
            ))}
          </>
        )}
      </>
    </div>
  );
};

interface PaymentMethodCardProps {
  method: PaymentMethod;
  handleDeleteMethod: () => void;
  handleSetDefault: () => void;
}

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({
  method,
  handleDeleteMethod,
  handleSetDefault,
}) => {
  const isPayPal = method.type == 'paypal';

  return (
    <div className="flex h-[76px] w-full flex-col  justify-center rounded-xl border  px-4 py-[7px] ">
      <div className="flex items-center">
        <div className="flex h-8 w-full  items-center justify-between">
          <div className="mr-2  flex items-center justify-center   px-1 py-[7px]">
            <div className="rounded-sm bg-surface-grey p-1">
              {isPayPal ? (
                <PayPallLogoColor className="h-full w-[25px]" />
              ) : method.cardInfo.type === 'visa' ? (
                <VisaCardLogo />
              ) : (
                <MasterCardIcon />
              )}
            </div>
            <div className="w-[211px] pl-[10px] text-[16px] font-medium leading-normal text-black">
              {isPayPal ? method.name : `•••• •••• ••••  ${method.cardInfo.last4}`}
            </div>
          </div>

          <div className="flex items-center">
            <div className="mb-1 flex">
              {method.isDefault && <span className=" text-gray-400">Default</span>}
            </div>
            <ActionPaymentMethod onDelete={handleDeleteMethod} onSetDefault={handleSetDefault} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodsCards;
