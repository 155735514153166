//Get Account's Todos and Action Items
export const GET_DASHBOARD = ({ companyId }: { companyId: number }) =>
  `/company/${companyId}/dashboard`;

//Get runnable payrolls for a company
export const GET_PAYROLLS = ({ companyId }: { companyId: number }) =>
  `/company/${companyId}/pay-employee/get-runnable`;

//Get runnable active employee for a company
export const GET_RUNNABLE_EMPLOYEES = ({ companyId }: { companyId: number }) =>
  `/company/${companyId}/pay-employee/get-active`;

export const SAVE_ACCOUNT_DETAILS = ({ accountId }: { accountId: number | undefined }) =>
  `/account/${accountId}`;

export const SAVE_COMPANY_DETAILS = ({ companyId }: { companyId: number | undefined }) =>
  `company/${companyId}`;

export const GET_PAYROLL_HISTORY = ({ companyId }: { companyId: number | undefined }) =>
  `/company/${companyId}/employees/payroll-history`;
