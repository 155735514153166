import { Table, TableBody, TableHead, TableHeader, TableRow } from '@/components';

import BillingHistoryView from '../BillingHistoryView/BillingHistoryView';

const BillingHistoryTable = ({ data }: any) => {
  return (
    <div className="hidden rounded-xl border sm:flex ">
      <Table>
        <TableHeader>
          <TableRow className="text-sm text-gray-300">
            <TableHead className="w-1/3 px-4 py-4 ">Plan type</TableHead>

            <TableHead className=" w-1/3 px-4 py-4 text-start">Date</TableHead>
            <TableHead className="w-1/3  px-4 py-4 text-end">Amount</TableHead>
            <TableHead className="px-4  py-4 text-end ">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className="text-base">
          {!!data.length &&
            data.map((bill: any) => (
              <TableRow key={bill.id}>
                <BillingHistoryView data={bill} isCardView={false} />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default BillingHistoryTable;
