import { Dispatch, RootState } from '@/redux/store';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useAddNewPaymentMethod = ({ onClose }: any) => {
  const dispatch = useDispatch<Dispatch>();
  const stripe = useStripe();
  const elements = useElements();
  const [step, setStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessageStripe, setErrorMessageStripe] = useState<string | null>(null);

  const email = useSelector((state: RootState) => state?.auth?.user?.account?.email);
  const firstName = useSelector((state: RootState) => state?.auth?.user?.account?.firstName);

  const [isCardNumberValid, setIsCardNumberValid] = useState<boolean>(false);
  const [isCardExpiryValid, setIsCardExpiryValid] = useState<boolean>(false);
  const [isCardCvcValid, setIsCardCvcValid] = useState<boolean>(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleCardNumberChange = (event: any) => {
    setIsCardNumberValid(event.complete && !event.error);
  };

  const handleCardExpiryChange = (event: any) => {
    setIsCardExpiryValid(event.complete && !event.error);
  };

  const handleCardCvcChange = (event: any) => {
    setIsCardCvcValid(event.complete && !event.error);
  };

  const onClickPayPall = async () => {
    const response = await dispatch.auth.setupPayPallToken();
    window.location.assign(response.approveUrl);
  };

  const createPurchaseStripe = async () => {
    try {
      setIsLoading(true);

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      if (!cardElement) {
        return;
      }

      const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        billing_details: {
          name: firstName,
          email: email,
        },
        card: cardElement,
      });

      if (paymentError) {
        setErrorMessageStripe(paymentError.message || null);
        return;
      }

      await dispatch.auth.createPaymentMethodStripe({ paymentMethodId: paymentMethod.id, email });
      await dispatch.auth.getSubscriptionStatus();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  const clearStateAndCloseModal = () => {
    setStep(1);
    setIsCardNumberValid(false);
    setIsCardCvcValid(false);
    setIsCardNumberValid(false);
    onClose();
  };

  const handleCloseModal = () => {
    if (isCardCvcValid || isCardExpiryValid || isCardNumberValid) {
      setIsConfirmationModalOpen(true);
    } else {
      clearStateAndCloseModal();
    }
  };
  const confirmUnsavedChangesHandler = () => {
    setIsConfirmationModalOpen(false);
    clearStateAndCloseModal();
  };

  const cancelUnsavedChangesHandler = () => {
    setIsConfirmationModalOpen(false);
  };

  return {
    createPurchaseStripe,
    handleCardNumberChange,
    onClickPayPall,
    handleCardCvcChange,
    handleCardExpiryChange,
    isCardExpiryValid,
    isCardNumberValid,
    isCardCvcValid,
    step,
    setStep,
    isLoading,
    errorMessageStripe,
    handleCloseModal,
    confirmUnsavedChangesHandler,
    cancelUnsavedChangesHandler,
    isConfirmationModalOpen,
  };
};
export default useAddNewPaymentMethod;
