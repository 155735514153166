export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

// TO GET THE OBJECT VALUE FROM ARRAY OF OBJECTS
export const getObjectValues = dataArray => {
  if (dataArray.length > 0) {
    return dataArray.map(obj => Object?.values(obj)[0]);
  }
};

export const findValueByKey = (array, key: string) => {
  if (array.length > 0) {
    for (const obj of array) {
      if (key in obj) {
        return obj[key];
      }
    }
    return undefined; // Key not found
  }
};

export const findKeyByValue = (arrayOfObjects, targetValue) => {
  for (let i = 0; i < arrayOfObjects.length; i++) {
    const currentObject = arrayOfObjects[i];
    const keys = Object.keys(currentObject);

    for (let j = 0; j < keys.length; j++) {
      const key = keys[j];
      const value = currentObject[key];

      if (value === targetValue) {
        return key;
      }
    }
  }

  // Return null if the value is not found
  return null;
};
